import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/Breadcrumb/actions";
import NewContractor from "../../../component/newContractor/newContractor";
import AddRateModal from "../../../component/newContractor/addRateModal";

import * as APIs from '../../../APICall/urls/contractor';
import { postAdminAPI } from '../../../APICall/index';
import { notiflixLoaderStart, notificationStatus, notiflixLoaderEnd } from '../../../component/Notiflix/notiflix'
import ConfirmModal from "../../../component/confirm/confirmForOrder";
const AllNewContractor = (props) => {
    const [state, setState] = useState({
        breadCrumbItems: [],
        allData: [],
        viewModal: false,
        approveModal: false,
        rejectModal: false,
    })

    const [doc, setDoc] = useState([])

    useEffect(() => {
        props.setBreadcrumbItems("Pay Rates Setup", state.breadCrumbItems);
        getAllData()
    }, []);

    const getAllData = async (contractor) => {
        let result = await postAdminAPI(APIs.GET_NEW_CONTRACTOR, {})
        console.log(result);
        if (result.status) {
            let _new = result.data.filter((d) => d.is_approved === "PENDING")
            setState({ ...state, allData: _new })
        }
    }

    const getDocument = async (contractor) => {
        notiflixLoaderStart();
        let result = await postAdminAPI(APIs.GET_CONTRACTOR_CRED, {ctr:contractor.id})
        console.log(result);
        if (result.status) {
            let _new = result.data.filter((d) => d.uploaded?.length)
            setDoc(_new)
        }
        notiflixLoaderEnd();
    }

    const handleApprove = async () => {
        notiflixLoaderStart();
        let data = {
            id: state.approveModal.id,
            is_approved: "YES"
        }
        // console.log(shift, data);
        let result = await postAdminAPI(APIs.APPROVE_NEW_CONTRACTOR, data)
        console.log(result);
        if (result) {
            console.log(result.data)
            notificationStatus(result)
            setState({ ...state, approveModal: false })
            setTimeout(() => {
                window.location.reload();
            },[1000])
        }
        notiflixLoaderEnd()
    }


    const handleReject = async () => {
        notiflixLoaderStart();
        let data = {
            id: state.selectedShift,
            status: 0
        }
        // console.log(shift, data);
        let result = await postAdminAPI(APIs.REJECT_NEW_CONTRACTOR, data)
        console.log(result);
        if (result) {
            console.log(result.data)
            notificationStatus(result)
            if (result.status)
                getAllData()
        }
    }

    return (
        <div>
            <NewContractor
                allData={state.allData}
                handleApprove={(d) => setState({...state, approveModal: d})}
                handleReject={(d) => setState({...state, rejectModal: d})}
                handleView={(d) => getDocument(d)}
            />
            {
                state.approveModal
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to approve this contractor?",
                                proceed: true
                            }
                        }
                        title="Approve Contractor"
                        toggle={() => setState({ ...state, approveModal: false })}
                        click={handleApprove}
                    />
                    : null
            }
            {
                state.rejectModal
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to reject this contractor?",
                                proceed: true
                            }

                        }
                        title="Reject contractor"
                        toggle={() => setState({ ...state, rejectModal: false })}
                        click={handleReject}
                    />
                    : null
            }
            {
                doc?.length
                    ?
                    <AddRateModal
                        isOpen={true}
                        data={doc}
                        handleClose={() => setDoc(false)}
                    />
                    : null
            }
        </div>
    )

};

export default connect(null, { setBreadcrumbItems })(AllNewContractor);

