import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import {dateUSStandard} from '../../Utils/dateFunctions';

const size = [0, 1, 2, 3, 4, 5, 6];

const AddRateCard = (props) => {
    const [d, setD] = useState([])
    useEffect(() =>{
        if(props.data.length){
            allData()
        }
    },[props.data])
    const allData = () =>{
        let data = [...props.data];
        let _new = [];
        data.forEach(d => {
            if(d.uploaded.length > 0 && _new.findIndex(x => x.code === d.code) === -1){
                _new.push({
                    code:d.code,
                    des:d.des,
                    filename:d.uploaded[0]?.filename,
                    issue_date:d.uploaded[0]?.issue_date,
                    exp_date:d.uploaded[0]?.exp_date,
                })
            }
        })
        setD(_new)
    }

    return (
        <Modal size="xl" isOpen={props.isOpen}>
            <ModalHeader>
                All Document
                <CloseIcon
                    className={`header-close-icon`}
                    onClick={() => props.handleClose()}
                />
            </ModalHeader>
            <ModalBody>
                <form >
                    <div className="row">
                        <table className="table" >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Issue Date</th>
                                    <th>Expiry Date</th>
                                    <th>Document</th>
                                </tr>
                            </thead>
                            <tbody id="rates_body">
                                {
                                    d.map((s, i) => (
                                        <tr key={i}>
                                            <td>{s.des}</td>
                                            <td>{dateUSStandard(s.issue_date)}</td>
                                            <td>{dateUSStandard(s.exp_date)}</td>
                                            <td><a href={s.filename} target="_blank" rel="noopener noreferrer">View File</a></td>
                                        </tr>
                                    ))}

                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <button
                                type="button"
                                className="btn btn-danger suspend"
                                onClick={() => props.handleClose()}
                            >Close</button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AddRateCard;