import React, { Component } from "react";
import {
    Row,
    Col,
} from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import Navigation from '../../component/StaffingSheet/Navigation';
import { dateStandard, dateArithmetic, dateDifference } from '../../Utils/dateFunctions';
import Calender from './Calender'
import ConfirmModal from "../../component/confirm/confirmForOrder";

import "./calendar.scss";
import ModalWrapper from '../StaffingSheet/ModalWrapper/ModalWrapper';
import { postAdminAPI, getAdminAPI } from '../../APICall/index';
import { GET_ALL_SHIFT, GET_CONTRACTOR_TYPE, CREATE_SHIFT, EDIT_SHIFT, GET_SHIFT_MINI, GET_COMMENT, ADD_COMMENT, GET_HISTORY, ADD_NOTIFICATION, GET_CLIENT_CONTACT, APPROVE_SHIFT, ORDER_STATE, SHIFT_REPLACEMENT } from '../../APICall/urls';
import { notiflixLoaderStart, notiflixStatus, notiflixLoaderEnd, notificationStatus } from '../../component/Notiflix/notiflix';
import { generateNotificationStringWithName } from "../../Utils/orderfunctions";


class StaffingSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            breadcrumbItems: [
                // { title: "Calender", link: "#" },
            ],
            selectedClient: null,
            shifts: [],
            contractorType: [],
            selectedDate: null,
            allOrders: [],

            selectedShift: null,
            selectedDate: null,
            selectedOrder: null,

            selectedComments: [],

            selectedHistory: [],

            loading: false,
            error: null,
            message: null,
            allContacts: [],
            modalConfirm: null,
            approveInfo: {},
            change: false,
            allStateFromAPI: []

        };
    }

    afterActionCalls = (content, callback, orderId) => {
        notiflixStatus(content.status, content.message);
        if (content.status) {
            if (content.status) {
                this.fetchBasicAPIs(this.state.startDate, this.state.endDate);
            }
            this.setState({  ...this.state,change: false });
            if (callback) {
                callback(orderId)
            }
            if (orderId)
                this.setState({
                    ...this.state,
                    selectedOrder: {
                        ...this.state.selectedOrder,
                        id: orderId
                    }
                })
        }
        // this.fetchMerchandiseProduct(this.state.productId);
    }

    componentDidMount() {
        let client = localStorage.getItem("RNPlusClient")
        let _today = dateStandard(new Date());
        //check data from query params
        const queryParams = new URLSearchParams(this.props.location.search);  
        if(queryParams?.get("start")){
            _today = queryParams.get("start")
        }
        
        // const params = {};  
        
        // for (const [key, value] of queryParams.entries()) {  
        // params[key] = value;  
        // }  
        this.setState({
            ...this.state,
            startDate: _today,
            endDate: dateArithmetic(_today, 14),
            selectedClient: client
        })
        this.props.setBreadcrumbItems("Staffing Sheet", this.state.breadcrumbItems);
        if(client && parseInt(client) > 0){
            this.fetchBasicAPIs(_today, dateArithmetic(_today, 14), client);
            this.getContact(client)
            this.getAllInitialData()
        }
        else
            notificationStatus({status:false, message:"Please select client to staffing sheet"})
        // this.updateDataUsingFireBase(_today, dateArithmetic(_today, 14));

    }

    getAllInitialData = async () => {
        let _label = await getAdminAPI(ORDER_STATE)
        if (_label?.length) {
            this.setState({ ...this.state, allStateFromAPI: _label });
        }
    }


    componentDidUpdate(prevProps) {
        // console.log(prevProps.basicDetails, this.props.basicDetails)
        if (prevProps.basicDetails && (prevProps.basicDetails.realtimeTimeStamp != this.props.basicDetails.realtimeTimeStamp)) {
            // console.log(this.state.startDate, this.state.endDate, this.state.selectedClient);
            this.fetchBasicAPIs(this.state.startDate, this.state.endDate, this.state.selectedClient);
        }
    }

    fetchBasicAPIs = async (startDate, endDate, selectedClient) => {
        // console.log("fetching data")
        // console.log(startDate, endDate, selectedClient)
        if (!selectedClient)
            selectedClient = this.state.selectedClient

        // console.log(contractors, shifts, clients, contractorType)
        let shiftInfo = {
            "client": selectedClient,
            "startDate": startDate,
            "endDate": endDate
        }

        let shifts = await postAdminAPI(GET_ALL_SHIFT, { "client": selectedClient });
        // console.log(shifts)
        if (shifts.status && shifts.data) {
            let _shifts = [...shifts.data]

            //get only active shifts
            _shifts = _shifts.filter((f) => f.is_active === 1 && f.deleted === 0)
            _shifts.sort(function (a, b) {
                return parseInt(a.id) - parseInt(b.id);
            });

            this.setState({ ...this.state, shifts: _shifts })

        }



        let getShits = await postAdminAPI(GET_SHIFT_MINI, shiftInfo);
        // console.log(getShits)
        this.setState({ ...this.state, allOrders: getShits.data })

        this.getContractorInfo()

        // console.log(contractors, shifts, clients, contractorType);
        this.setState({ ...this.state })
    }


    getContractorInfo = async () => {
        let contractorType = await postAdminAPI(GET_CONTRACTOR_TYPE);
        this.setState({ ...this.state, contractorType: contractorType.data })
    }

    getContact = async (client) => {
        let data = {
            "client_id": client
        };
        let result = await postAdminAPI(GET_CLIENT_CONTACT, data)
        if (result.status) {
            this.setState({ ...this.state, allContacts: result.data })
        }
    }

    handleDateChange = (type, date) => {
        // console.log(type, date)
        if (type === "start") {
            let endDate = dateArithmetic(date, 14)
            this.setState({ ...this.state, startDate: date, endDate: endDate })
            this.fetchBasicAPIs(date, endDate)
        }
        else {
            let startDate = dateArithmetic(date, -14)
            this.setState({ ...this.state, endDate: date, startDate: startDate })
            this.fetchBasicAPIs(startDate, date)
        }



    }

    handleDateArithmetic = (count) => {
        // console.log(count)
        let newDate = {
            startDate: dateArithmetic(this.state.startDate, count),
            endDate: dateArithmetic(this.state.endDate, count)
        }
        this.setState({ ...this.state, ...newDate})
        this.fetchBasicAPIs(newDate.startDate, newDate.endDate)
    }

    handleStaffingSheetModal = async (selectedShift, dayCount, selectedOrder) => {
        //   console.log(this.state)
        let comments;
        // console.log(selectedOrder);
        if (selectedOrder && Object.keys(selectedOrder).length > 0) {
            this.getComments(selectedOrder.id);
            this.getHistory(selectedOrder.id);

        }
        else {
            selectedOrder = null
        }
        // console.log(selectedOrder);
        this.setState({
            ...this.state,
            modal: !this.state.modal,
            selectedShift: selectedShift,
            selectedDate: dateArithmetic(this.state.startDate, dayCount),
            selectedOrder: selectedOrder,
        })
    }

    handleSubmitShift = async (shiftInfo, shiftId, actionAllowed, actionMessage, isReplacement, callback) => {
        console.log(isReplacement);
        if (actionAllowed) {
            notiflixLoaderStart();
            let content;
            if (shiftId) {
                shiftInfo = {
                    ...shiftInfo,
                    id: parseInt(shiftId)
                }
                content = await postAdminAPI(EDIT_SHIFT, shiftInfo);
            }
            else {
                content = await postAdminAPI(CREATE_SHIFT, shiftInfo);
                console.log(content);
                let selectedOrder = {}
                selectedOrder.id = content.data[0]
                this.setState({
                    ...this.state,
                    selectedOrder:selectedOrder
                })
            }

            if (content.status) {
                // if (content.data === true) {
                //     if (isReplacement)
                //         await this.shiftReplacement(shiftId)

                //     this.afterActionCalls(content, callback, shiftId ? shiftId : content.data[0]);
                // }
                if (content.data === false) {
                    notiflixStatus(false, "Something went wrong.");
                }
                else if (typeof content.data === "string") {
                    notiflixStatus(false, content.data);
                }
                else {
                    if (isReplacement)
                        await this.shiftReplacement(shiftId)

                    this.afterActionCalls(content, callback, shiftId || content.data[0]);
                }

                if(isReplacement){
                    this.handleStaffingSheetModal()
                }
            }
            else {
                notiflixStatus(false, content.message);
            }
        }
        else {
            notiflixStatus(false, actionMessage);
        }
    }

    shiftReplacement = async (id) => {
        // console.log(id);
        if (id) {
            let data = {
                "f_id": id,
                "isCallOut": true,
                "result": 1
            }
            // console.log(data);
            await postAdminAPI(SHIFT_REPLACEMENT, data);
        }
    }
    // Response
    handleResponse = async (shiftId, contId, ctrType, payableCtrType) => {
        // console.log(shiftId, contId, ctrType);
        notiflixLoaderStart();
        let shiftInfo = {
            "id": shiftId,
            "assignedCtr": contId,
        }
        let content = await postAdminAPI(APPROVE_SHIFT, shiftInfo);
        // console.log(content);
        if (content.status === false) {
            notiflixStatus(false, content.message);
        }
        else {
            if (content.data?.showPrompt) {
                this.setState({
                    ...this.state,
                    approveInfo: {
                        "id": shiftId,
                        "assignedCtr": contId,
                        "state": 1,
                        ctrType,
                        payableCtrType:payableCtrType
                    }
                })
                let message = generateNotificationStringWithName(content.data?.extra?.pastOrders, content.data?.extra?.futureOrders, content.data?.extra, content.data?.message)
                message = message || content.data?.message
                this.setState({ ...this.state, modalConfirm: { ...content.data, message:message } });
            }
            else {
                let content = await postAdminAPI(EDIT_SHIFT, {
                    "id": shiftId,
                    "assignedCtr": contId,
                    "state": 1,
                    ctrType,
                    payableCtrType:payableCtrType
                });
                this.setState({ ...this.state, modal: false });
                this.afterActionCalls(content);

            }
            notiflixLoaderEnd()
        }
        // console.log(content);
    }

    handleApprove = async () => {
        notiflixLoaderStart();
        let shiftInfo = { ...this.state.approveInfo }
        let content = await postAdminAPI(EDIT_SHIFT, shiftInfo);
        this.setState({ ...this.state, modal: false, modalConfirm: false });
        this.afterActionCalls(content);
        notiflixLoaderEnd()
    }

    //Notification
    handleAddNotification = async (posData) => {
        this.setState({ ...this.state, modal: false });
        let notification = await postAdminAPI(ADD_NOTIFICATION, posData)
        // console.log(notification)
        this.afterActionCalls(notification);
    }
    //Comment
    getComments = async (orderId) => {
        let comments = await postAdminAPI(GET_COMMENT, { order: orderId })
        // console.log(comments.data)
        this.setState({
            ...this.state,
            selectedComments: comments ? comments.data : []
        })
    }

    handleAddComment = async (commentData) => {
        notiflixLoaderStart();
        let data = {
            ...commentData,
            order: this.state.selectedOrder.id
        }
        // console.log(data)
        let comments = await postAdminAPI(ADD_COMMENT, data)
        // console.log(comments)
        await this.getComments(this.state.selectedOrder.id);
        this.afterActionCalls(comments);
    }

    //History
    getHistory = async (orderId) => {
        let history = await postAdminAPI(GET_HISTORY, { order: orderId })
        // console.log(history.data)
        this.setState({
            ...this.state,
            selectedHistory: history ? history.data : []
        })
    }

    

    render() {
        const { startDate, endDate, shifts, contractorType, selectedDate, selectedShift, selectedOrder, allOrders, selectedComments, selectedHistory, allContacts,selectedClient, modalConfirm } = this.state;
        return (
            <>
                <Row style={{ marginTop: -35 }}>
                    <Col xs="12">
                        {
                            startDate
                                ?
                                <>
                                    <Navigation
                                        startDate={startDate}
                                        endDate={endDate}
                                        handleDateChange={this.handleDateChange}
                                        handleDateArithmetic={this.handleDateArithmetic}
                                    />
                                    <br />
                                    {
                                        allOrders && shifts && shifts.length > 0
                                            ?
                                            <Calender
                                                days={dateDifference(startDate, endDate)}
                                                startDate={startDate}
                                                handleModal={this.handleStaffingSheetModal}
                                                allShifts={shifts ? shifts : []}
                                                allOrders={allOrders}
                                                afterActionCalls={this.afterActionCalls}
                                            />
                                            : null
                                    }


                                </>

                                :
                                null
                        }
                    </Col>
                </Row>
                {
                    this.state.modal
                        ?
                        <ModalWrapper
                            handleClose={this.handleStaffingSheetModal}
                            isOpen={this.state.modal}
                            clients={this.props.basicDetails.allClients}
                            contractors={this.props.basicDetails.allContractor}
                            shifts={shifts}
                            contractorType={contractorType}
                            selectedClient={selectedClient}
                            selectedDate={selectedDate}
                            selectedShift={selectedShift}
                            handleSubmitShift={this.handleSubmitShift}
                            selectedOrder={selectedOrder}

                            handleAddNotification={this.handleAddNotification}

                            handleResponse={this.handleResponse}

                            handleAddComment={this.handleAddComment}
                            selectedComments={selectedComments}

                            selectedHistory={selectedHistory}

                            allContacts={allContacts}
                            change={this.state.change}
                            handleChange={() => this.setState({ ...this.state, change: true })}
                            allState={this.state.allStateFromAPI}
                        />
                        : null
                }
                {
                    modalConfirm
                        ?
                        <ConfirmModal
                            modal={modalConfirm}
                            title="Approve Shift"
                            content="The contractor has applied to another same shift with bonus. Do you want to continue?"
                            toggle={() => this.setState({ ...this.state, modalConfirm: null })}
                            click={() => this.handleApprove()}
                        />
                        : null
                }

            </>
        );
    }
}

const mapStateToProps = state => {

    return {
        basicDetails: state.BasicDetails
    }

}

export default connect(mapStateToProps, { setBreadcrumbItems })(StaffingSheet);
