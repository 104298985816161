import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { MDBDataTable } from 'mdbreact';
import { setBreadcrumbItems } from "../../../store/actions";
import { GET_ALL_INVOICE, GET_SINGLE_INVOICE, DELETE_INVOICE, MAIL_INVOICE } from "../../../APICall/urls/invoicePayroll";
import { postAdminAPI } from "../../../APICall/index";
import { dateUSStandard } from "../../../Utils/dateFunctions";
import ConfirmModal from "../../../component/confirm";
import { notiflixLoaderStart,notiflixLoaderEnd, notiflixStatus, notificationStatus } from '../../../component/Notiflix/notiflix';
import "./style.css";
import { toFixed } from '../../../Utils/stringManupulation';
import DatePicker from '../../../component/datePicker/datePicker';
import Required from '../../../component/Required/star';
import { getIdsInArray } from '../../../Utils/arrayFunction';
import { currentDate, dateStandard, dateArithmetic } from '../../../Utils/dateFunctions';

const ViewAllPayroll = (props) => {

    const [breadCrumbItems, setBreadCrumbItem] = useState([
        { title: "Payroll", link: "#" },
        { title: "View", link: "#" },
    ]);
    const dispatch = useDispatch();

    const [columns, setColumns] = useState([
        {
            label: <label className="container">
                <input
                    type="checkbox"
                    value={"all"}
                    className="form-control"
                    onClick={(e) => handleCheckboxAll(e, rawData)}
                // checked={() => this.checkAllChech()}
                />
                <span className="checkmark"></span>
            </label>,
            field: 'select',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Invoice No',
            field: 'invoice',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Bill To',
            field: 'bill',
            sort: 'asc',
            width: 270
        },
        {
            label: 'Invoice Date',
            field: 'date',
            sort: 'asc',
            width: 200
        },
        {
            label: 'Total Amount($)',
            field: 'amount',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Regular Hours',
            field: 'regular',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Holiday Hours',
            field: 'holiday',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Total Hours',
            field: 'hours',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Action',
            field: 'action',
            sort: 'asc',
            width: 100
        }
    ])
    const [rawData, setRawData] = useState([]);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [mailId, setMailId] = useState(new Set())
    const [filter, setFilter] = useState({
        clients: '',
        startDate: dateArithmetic(currentDate(), -60),
        endDate: dateStandard(currentDate()),
    });

    const updateFilter = (e, name) => {
        let _filter = { ...filter };
        _filter[name] = e.target.value;
        setFilter(_filter)
    }

    const updateFilterDate = (name, date) => {
        let _filter = { ...filter };
        _filter[name] = date;
        setFilter(_filter)
    }

    useEffect(() => {
        if (rawData.length) {
            let _column = [...columns]
            _column[0] = {
                label: <label className="container">
                    <input
                        type="checkbox"
                        value={"all"}
                        className="form-control"
                        onClick={(e) => handleCheckboxAll(e, rawData)}
                    // checked={() => this.checkAllChech()}
                    />
                    <span className="checkmark"></span>
                </label>,
                field: 'select',
                sort: 'asc',
                width: 150
            }
            setColumns(_column)
        }

    }, [rawData])

    useEffect(() => {
        if(props.basicDetails.allClients){
            dispatch(setBreadcrumbItems("SetUp", breadCrumbItems));
            // let _allClient = getIdsInArray(props.basicDetails.allClients, "id")
            // let _filter = {...filter}
            // delete _filter.clients
            // _filter.clients = [..._allClient];
            fetchAllData(null, filter);
        }
    }, [props.basicDetails.allClients])

    const fetchAllData = async (e, f) => {
        notiflixLoaderStart()
        if(e){
            e.preventDefault()
        }
        let filter = f ? {...f}: {}
        if(!filter.clients){
            delete filter.clients;
        }
        if(filter.clients && typeof filter.clients == "string"){
            filter.clients = [filter.clients]
        }
        let result = await postAdminAPI(GET_ALL_INVOICE,filter);
        if (result.status) {
            setRawData(result.data)
            // fetchAllInvoice(result.data, mailId);
        }
        notiflixLoaderEnd()
    }

    const fetchAllInvoice = (allData, mailId) => {
        let _allData = [];
        console.log(mailId);
        let amount = 0;
        let regular = 0;
        let holiday = 0;
        allData.forEach(h => {

            amount += h.amount;
            regular += h.regular_hours;
            holiday += h.holiday_hours;

            _allData.push({
                select: <input
                    type="checkbox"
                    checked={mailId.has(h.id.toString()) ? true : false}
                    className="form-control"
                    onChange={(e) => handleChange(e, h.id, allData)}
                />,
                invoice: h.id,
                bill: h.client_name,
                date: h.invoice_date ? dateUSStandard(h.invoice_date) : "",
                amount: toFixed(h.amount),
                regular: toFixed(parseFloat(h.regular_hours)),
                holiday: toFixed(parseFloat(h.holiday_hours)),
                hours: toFixed(parseFloat(h.regular_hours + h.holiday_hours)),
                action:
                    <>
                        <a href={h.path} target="_blank"><button
                            className="btn btn-primary"
                        >View</button></a>
                        &nbsp;
                    </>
                ,
            });
        })

        _allData.push({
            select: "",
            invoice: "",
            bill: <strong>TOTAL</strong>,
            date: "",
            amount: toFixed(amount),
            regular: toFixed(regular),
            holiday: toFixed(holiday),
            hours: toFixed(regular + holiday),
            action: ""
        });

        return _allData;
        // setAllData(_allData)
    }

    const handleViewInvoice = async (id) => {
        let data = {
            invoiceNumber: id
        }
        let result = await postAdminAPI(GET_SINGLE_INVOICE, data);
        console.log(result)
    }

    const handleEditInvoice = (id) => {

    }

    const updateDelete = (id) => {
        if ([...mailId].length) {
            setModalConfirm(true);
        }
        else {
            notificationStatus({ status: false, message: "Please select atleast one client to proceed." })

        }


    }
    const handleDelete = async () => {
        notiflixLoaderStart();
        setModalConfirm(false);
        let result = await postAdminAPI(DELETE_INVOICE, { invoice_id: [...mailId] });
        // console.log(result)
        notiflixStatus(result.status, result.message);
        if (result.status) {
            fetchAllData(null, filter);
            setMailId(new Set())
        }
    }

    const sendMail = async () => {
        if ([...mailId].length > 0) {
            notiflixLoaderStart();
            setModalConfirm(false);
            let result = await postAdminAPI(MAIL_INVOICE, { invoice_ids: [...mailId] });
            notiflixStatus(result.status, result.message);
            if (result.status) {
                setMailId(new Set())
                // fetchAllInvoice(rawData, [])
            }
        }
        else {
            notiflixStatus(false, "Please select an invoice to mail.");
        }

    }

    const handleChange = (e, id) => {
        let _mailId = new Set([...mailId])
        if (e.target.checked) {
            _mailId.add(id.toString())
            setMailId(_mailId)
        }
        else {
            _mailId.delete(id.toString())
            setMailId(_mailId)
        }
        // fetchAllInvoice(allData, _mailId)
    }

    const handleCheckboxAll = (event, rawData) => {
        let _mailId = new Set([...mailId])
        console.log(event.target.checked, rawData);
        if (event.target.checked) {
            rawData.map((q, i) => {
                _mailId.add(q.id.toString());
            })
        }
        else {
            rawData.map((q, i) => {
                _mailId.delete(q.id.toString());
            })
        }
        setMailId(_mailId)
    }

    console.log(filter.startDate);
    return (
        <Card className="invoiceCard">
            <CardBody>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button
                            className="btn btn-primary"
                            onClick={() => sendMail()}
                        >Mail</button>
                        &nbsp;
                        <button
                            className="btn btn-danger"
                            onClick={() => updateDelete()}
                        >Delete</button>
                    </div>
                </div>
                <form onSubmit={(e) => fetchAllData(e, filter)}>
                    <Row>
                        <Col md={4}>
                            <label>Clients<Required /></label>
                            <select
                                className="form-control"
                                value={filter.clients}
                                onChange={(e) => updateFilter(e, "clients")}
                            >
                                <option value="" className="allOptions">All Clients</option>
                                {
                                    props.basicDetails.allClients && props.basicDetails.allClients.map((a, i) => (
                                        <option key={i} value={a.id}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </Col>
                        <Col md={3}>
                            <label>Invoice Start Date</label>
                            <DatePicker
                                date={filter.startDate}
                                name={"startDate"}
                                required={false}
                                handleDateChange={updateFilterDate}
                            />
                        </Col>
                        <Col md={3}>
                            <label>Invoice End Date</label>
                            <DatePicker
                                date={filter.endDate}
                                name={"endDate"}
                                required={false}
                                handleDateChange={updateFilterDate}
                            />
                        </Col>
                        <Col md={2} className="text-center mt-4">
                            <button type="submit" className="btn btn-primary">
                               Filter
                            </button>
                        </Col>

                    </Row>
                </form>
                {
                    rawData.length > 0 &&
                    <MDBDataTable
                        striped
                        hover
                        bordered
                        className='invoiceTableClass'
                        data={{ columns: columns, rows: fetchAllInvoice(rawData, mailId) }}
                    />
                }

            </CardBody>
            {
                modalConfirm
                    ?
                    <ConfirmModal
                        modal={modalConfirm}
                        title="Delete Invoice"
                        content="Do you want to delete this invoice."
                        toggle={() => setModalConfirm(false)}
                        click={() => handleDelete()}
                    />
                    : null
            }
        </Card>
    )
}

const mapStateToProps = state => {

    return {
        basicDetails: state.BasicDetails
    }

}

export default connect(mapStateToProps)(ViewAllPayroll);
