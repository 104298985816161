import React, { } from 'react';


const PayRateCard = (props) => {
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <nav className="navbar">
                    <h6 className="m-0 font-weight-bold text-primary ">New Contractors</h6>
                </nav>
            </div>

            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Contractor Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Type</th>
                                {/* <th>Added on</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody id="client_body">
                            {
                                props.allData.map((a, i) => (
                                    <tr key={i}>
                                        <td>{a.id}</td>
                                        <td>{a.name}</td>
                                        <td>{a.email1}</td>
                                        <td>{a.mob1}</td>
                                        <td>{a.code}</td>
                                        {/* <td>{a.added}</td> */}
                                        <td>
                                            <button
                                                className="btn btn-primary btn-circle1"
                                                onClick={() => props.handleView(a)}
                                            >
                                                <i className="fas fa-eye"></i>
                                            </button>
                                            &nbsp;
                                            <button
                                                className="btn btn-info btn-circle1"
                                                onClick={() => props.handleApprove(a)}

                                            >
                                                <i className="fas fa-cut"></i>
                                            </button>
                                            {/* &nbsp;
                                            <button
                                                className="btn btn-danger btn-circle1"
                                                onClick={() => props.handleReject(a)}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button> */}

                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default PayRateCard;