export const generateCellNumber = (str) => {
    if (str?.length) {
        console.log(str);
        str = str?.match(/\d+/g)?.join("");
        console.log(str);
        if (str?.length > 10) {
            str = str.slice(0, 10);
        }
        str = str?.replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '$1-$2-$3')
        return str;
    }
    return ""


}

export const generateSSNNumber = (str) => {
    if (str?.length) {
        str = str?.match(/\d+/g)?.join("");
        if (str.length > 9) {
            str = str.slice(0, 9);
        }
        str = str?.replace(/(\d{3})\-?(\d{2})\-?(\d{4})/, '$1-$2-$3')
        return str;
    }
    return "";

}

export function isNumeric(num){
    return !isNaN(num)
  }