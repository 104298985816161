import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { toggleSidebar, changeSidebarType } from "../../../store/actions";
import InformativeNotification from "../../Notiflix/informativeNotification";

//Import Menus
import FullScreen from "../Menus/full-screen";
import ProfileMenu from "../Menus/profile-menu";
import ModalClient from "../../Client/modalDuplicate";

//Import Images
import logodark from "../../../assets/images/RN_logo.png";

import { openBulkModal, closeBulkModal } from '../../../store/Modal/action';
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'
import { MAKE_DUPLICATE_CLIENT, FORMAT_DATA } from "../../../APICall/urls";
import { postAdminAPI, getAdminAPI } from "../../../APICall/index";
import { updateClient } from "../../../helpers/authUtils";
import ConfirmModal from "../../../component/confirm/confirmForOrder";
//css
import "./VerticalLayout.scss";

//components
import "../../../pages/Orders/CreateNewModal/CreateNewModal";

import ClientTopBar from "../../../pages/Client/ClientTopBar/ClientTopBar";
import { Button } from "reactstrap";

const clientOrders = ["Greenleaf Nursing", "Al bright"];

const clientPath = ["/order", "/staffing_sheet", "/client_setup", "/billing_rates", "/pay_rates", "/client_cred"];
const contractorPath = ["/contractor_setup", "/contractor_schedule", "/contractor_availability", "/contractor_pay_rates", "/contractor_dnr"]


class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),

            allClients: [],
            selectedClient: '',
            allContractor: [],
            selectedContractor: [],
            isAlways: false,
            modalDuplicate: false,
            options: [],
            formatModal: false,
        };
    }

    sidebarToggle = () => {
        if (this.props.leftSideBarType === "default") {
            this.props.changeSidebarType("condensed", this.state.isMobile);
        } else if (this.props.leftSideBarType === "condensed") {
            this.props.changeSidebarType("default", this.state.isMobile);
        }
    };

    openCreateNewOrderModal = () => {
        // console.log(this.props.bulkModal);
        this.props.openBulkModal();
        if (this.state.selectedClient !== "select") {
            this.setState({ isOpen: true })
        }
    };

    onModalClose = (bool) => {
        // console.log(this.props.bulkModal);
        this.props.openBulkModal();
        this.setState({ isOpen: bool });
    };

    selectedClientChange = (e) => {
        this.setState({ selectedClient: e.target.value });
        localStorage.setItem('RNPlusClient', e.target.value);
        window.location.reload();
    };

    selectedContractorChange = (e) => {
        this.setState({ selectedContractor: e.target.value });
        localStorage.setItem('RNPlusContractor', e.target.value);
        window.location.reload();
    };

    componentDidMount() {


        let allClients = JSON.parse(localStorage.getItem('RNPlusAllClient'));
        let selectedClient = parseInt(localStorage.getItem('RNPlusClient'));

        let allContractor = JSON.parse(localStorage.getItem('RNPlusAllContractor'));
        let selectedContractor = parseInt(localStorage.getItem('RNPlusContractor'));
        // console.log(allContractor)
        // Notif()
        // console.log(allContractor)
        this.setState({
            // allClients: allClients,
            selectedClient: selectedClient,
            // allContractor: allContractor,
            selectedContractor: selectedContractor
        })

        // console.log(allClients)
    }


    componentDidUpdate() {
        if ((this.props.basicDetails.allContractor && this.props.basicDetails.allContractor.length > 0) && (this.props.basicDetails.allContractorTypes && this.props.basicDetails.allContractorTypes.length > 0) && this.state.options.length === 0) {
            this.generateContOptions(this.props.basicDetails.allContractor, this.props.basicDetails.allContractorTypes)
            this.setState({
                allContractor: this.props.basicDetails.allContractor,
            })
        }


        if ((this.props.basicDetails.allClients && this.props.basicDetails.allClients.length > 0) && this.state.allClients.length === 0) {
            this.setState({
                allClients: this.props.basicDetails.allClients,
            })
        }
    }

    generateOptions = (allContractor, contractorType) => {
        let _options = []

        contractorType.map((c, i) => {
            let _temp = <option disabled style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>{c.code}</option>;
            let _tempOption = [];
            allContractor.map((a, j) => {
                if (a.type == c.id)
                    _tempOption.push(<option value={a.id}>{a.name + " " + c.code}</option>)
            })
            if (_tempOption.length > 0) {
                _options.push(_temp);
                _options = [
                    ..._options,
                    _tempOption
                ];
            }
        })
        this.setState({ options: _options })
    }


    generateContOptions = (allContractor, contractorType) => {
        // console.log(allContractor)
        let _options = []
        allContractor.map((a, j) => {
            if(a.is_approved === "YES")
                _options.push(<option value={a.id}>{a.name + " " + a.code}</option>)
        })
        this.setState({ options: _options })
    }

    checkPathName = () => {
        let result = ''
        let pathName = this.props.history.location.pathname;
        if (clientPath.indexOf(pathName) !== -1) {
            result = "Client"
        }
        else if (contractorPath.indexOf(pathName) !== -1) {
            result = "Contractor";
        }
        return result;
    }

    //Client
    handleAddNewClient = () => {
        localStorage.setItem("RNPlusClient", 0)
        window.location.reload();
    }

    createDuplicateClient = () => {

    }

    //Contractor
    handleAddNewContractor = () => {
        localStorage.setItem("RNPlusContractor", 0)
        window.location.reload();
    }

    handleAlwaysAvailable = (e) => {
        //api call
        if (e.target.checked) {

        }
        else {

        }
        this.setState({ isAlways: !this.state.isAlways })

    }

    toggleDuplicate = () => {
        this.setState({ modalDuplicate: !this.state.modalDuplicate })
    }

    handleDuplicate = async (data) => {
        // console.log(data);
        notiflixLoaderStart();
        let result = await postAdminAPI(MAKE_DUPLICATE_CLIENT, data)
        // console.log(result);
        if (result.status) {
            notificationStatus(result)
            this.getAllClients(result.data);
        }
        else{
            // notificationStatus(result)
            notificationStatus({status: false, message: "Duplicate client"});
        }
    }

    getAllClients = async (clntId) => {
        let data = await postAdminAPI('/client/get-name', {})
        // console.log(data)

        updateClient(data.data, clntId)
        window.location.reload()
    }

    handleFormat = async () => {
        notiflixLoaderStart();
        let result = await getAdminAPI(FORMAT_DATA)
        if (result) {
            window.location.reload()
        }
    }



    render() {
        // console.log(this.props.basicDetails)
        // console.log(this.props.history.location.pathname);
        const { modalDuplicate, allClients } = this.state;
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/staffing_sheet" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logodark} alt="" height="30" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logodark} alt="" height="60" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                type="button"
                                onClick={this.sidebarToggle}
                                className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                                id="vertical-menu-btn"
                            >
                                <i className="mdi mdi-menu"></i>
                            </button>
                        </div>
                        {
                            this.checkPathName() === "Client"
                                ? (
                                    <div className={`create-new-order-div`}>
                                        <select
                                            value={this.state.selectedClient}
                                            onChange={(e) => this.selectedClientChange(e)}
                                            className="form-control"
                                        >
                                            <option value={0}>Select Client</option>
                                            {this.state.allClients.map((c, i) => {
                                                return <option key={i} value={c.id}>{c.name}</option>;
                                            })}
                                        </select>
                                        {
                                            this.props.history.location.pathname === '/order'
                                                ?
                                                <button
                                                    className={`btn btn-primary new-order-button`}
                                                    type="button"
                                                    onClick={() => this.openCreateNewOrderModal()}
                                                >
                                                    New Order
                                                </button>
                                                : null
                                        }
                                        {
                                            this.props.history.location.pathname === '/client_setup'
                                                ?
                                                <>
                                                    <button
                                                        className="btn btn-outline-info"
                                                        type="button"
                                                        onClick={() => this.handleAddNewClient()}
                                                    >
                                                        Add New
                                                    </button> &nbsp;
                                                    <button
                                                        className="btn btn-outline-primary"
                                                        type="button"

                                                        onClick={() => this.toggleDuplicate()}
                                                    >
                                                        Duplicate
                                                    </button>
                                                </>
                                                : null
                                        }

                                    </div>
                                )
                                : null
                        }
                        {
                            this.checkPathName() === "Contractor"
                                ? (
                                    <div className={`create-new-order-div`}>
                                        <select
                                            value={this.state.selectedContractor}
                                            onChange={(e) => this.selectedContractorChange(e)}
                                            className="form-control"
                                        >
                                            <option value="select">Select Contractor</option>
                                            {this.state.options}
                                        </select>
                                        {
                                            this.props.history.location.pathname === '/contractor_setup'
                                                ?
                                                <>
                                                    <button
                                                        className="btn btn-outline-info"
                                                        type="button"
                                                        onClick={() => this.handleAddNewContractor()}
                                                    >
                                                        Add New
                                                    </button> &nbsp;
                                                </>
                                                : null
                                        }
                                        {/* {
                                            this.props.history.location.pathname === '/contractor_availability'
                                                ?
                                                <>
                                                    <div style={{ display: 'flex' }}>
                                                        <input
                                                            style={{ width: 16 }}
                                                            type="checkbox"
                                                            checked={this.state.isAlways}
                                                            onChange={(e) => this.handleAlwaysAvailable(e)}
                                                            id="always_avail"
                                                        />
                                                        &nbsp;

                                                            <label
                                                            style={{ marginBottom: 0 }}
                                                            htmlFor="always_avail">Always Available</label>
                                                    </div>
                                                </>
                                                : null
                                        } */}
                                    </div>
                                )
                                : null
                        }
                        <Button
                            onClick={() => this.setState({ ...this.state, formatModal: true })}

                        >Format</Button>
                        &nbsp;

                        &nbsp;
                        &nbsp;
                        <div className="d-flex">
                            <div className="live">
                                <div className="dot"></div>
                                <div>
                                    Live
                                </div>
                            </div>

                            <FullScreen />

                            <Link to="/notifications">
                                <div className="dropdown d-none d-lg-inline-block">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        data-toggle="fullscreen"
                                    >
                                        <i className="ti-bell font-size-23"></i>
                                    </button>
                                </div>
                            </Link>

                            {/* <NotificationMenu/> */}

                            <ProfileMenu />

                            {/* <SettingsButton/> */}
                        </div>
                    </div>
                </header>
                {
                    modalDuplicate
                        ?
                        <ModalClient
                            modal={modalDuplicate}
                            toggle={this.toggleDuplicate}
                            handleDuplicate={this.handleDuplicate}
                            allClients={allClients}
                        />
                        : null
                }
                {
                    this.state.formatModal
                        ?
                        <ConfirmModal
                            modal={
                                {
                                    message: "Do you want to format the current database?",
                                    proceed: true
                                }

                            }
                            title="Format Database"
                            toggle={() => {
                                this.setState({ ...this.state, formatModal: false })
                            }}
                            click={this.handleFormat}
                        />
                        : null
                }
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { is_toggle, leftSideBarType } = state.Layout;
    const bulkModal = state.Modal;
    const basicDetails = state.BasicDetails;
    return { is_toggle, leftSideBarType, bulkModal, basicDetails };
};

export default withRouter(
    connect(mapStatetoProps, { toggleSidebar, changeSidebarType, openBulkModal, closeBulkModal })(Topbar)
);
