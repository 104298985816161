import React, { Component, useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardBody,
} from "reactstrap";
import Table from '../../../../../component/DynamicTable';
import Search from '../../filter/payrollRegister';
import EmptyComponent from '../../../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../../../store/actions";
import { getWorkSummarReport, saveFilter, getColumns } from "../api";
import ModalLayout from "../../../../../component/ModalLayout";
import SaveFilter from "../../filter/saveFilter";
import GetFilter from "../../filter/getFilter";
import { generateFilter } from '../../filter/workSummaryFilter'
import * as helper from "../helper";
import { notiflixLoaderStart, notiflixLoaderEnd, notificationStatus } from '../../../../../component/Notiflix/notiflix';
const defaultColumn = ["Contractor_First_Name", "Contractor_Last_Name", "Client_Billing_Name", "hours_worked", "Cheque_Amount", "Cheque_Regular_Hours", "Cheque_Holiday_Hours", "Cheque_Start_Date", "Cheque_End_Date", "Cheque_Cheque_Date", "Cheque_Number(#)", "Contractor_Type"]

const OrderReport = (props) => {

    const [allData, setAllData] = useState(null)
    const [breadcrumbItems, setBreadcrumbItems] = useState([])
    const [changeColumn, setChangeColumn] = useState(false)
    const [columns, setColumns] = useState([])
    const [modalFilter, setModalFilter] = useState(false)
    const [filters, setFilters] = useState([])
    const [filterFromApply, setFilterFromApply] = useState(null)
    const [extraFilter, setExtraFilter] = useState([])
    const [sort, setSort] = useState(false)
    const [modalFilterSave, setModalFilterSave] = useState(false)
    const [modalFilterGet, setModalFilterGet] = useState(false)

    useEffect(() => {
        props.setBreadcrumbItems("Reports", breadcrumbItems);
    }, [])

    useEffect(() => {
        // getData(filters, columns, true)
    }, [])

    useEffect(() => {
        getAllColumns()
    }, [])

    const getAllColumns = async() =>{
        let res = await getColumns({reportName:"payroll_summary"})
        setColumns((helper.cretateColumn(res.generatedColumns, defaultColumn, res.dbColumns)))
    }

    const getData = async (filter, sort) => {
        console.log(filter, "dfdsf");
        let request = {
            "report_name": "payroll_summary",
            "filters": [
                ...filter
            ],
            "sort": helper.convertColumnForSort(sort ? sort : columns)
        }
        let data = await getWorkSummarReport(request)
        if (data) {
            updateData(data.data)
        }
    }

    const updateData = (data) => {
        if(data.length){
            let total = helper.generateCountForTable(data, ["hours_worked", "Cheque_Amount", "Cheque_Regular_Hours", "Cheque_Holiday_Hours"])
            setAllData([...data, total])
        }
        else{
            setAllData([...data])
        }
    }

    //From top filtres
    const handleFilter = async (filterData) => {
        setFilters(filterData)
        getData(helper.updateGeneralFilter(filterData, extraFilter), columns)
    }

    //From colum and sort
    const updateColumns = (columns, isCallAPI) => {
        console.log(columns);
        setColumns(columns)
        if (isCallAPI)
            getData(null, columns)
    }
    //From colum and sort

    //From Popup filter
    const updateExtraFilter = (extra) => {
        setExtraFilter(extra)
        getData(helper.updateExtraFilter(filters, extra), columns)
    }

    const saveFilterData = async (name) => {
        notiflixLoaderStart()
        let data = {
            "reportName": name.name,
            "reportType": "invoice_summary",
            "savedReportData": JSON.stringify({ filters: modalFilterSave, extraFilter, columns })
        }
        let res = await saveFilter(data)
        if (res) {
            setModalFilterSave(false)
            notificationStatus({ status: true, message: "Succcessfully saved" })
        }
        else {
            notificationStatus({ status: false, message: "Error in saving filter" })
        }
        notiflixLoaderEnd()
    }

    const applyFilter = async (data) => {
        let _data = JSON.parse(data)
        console.log(_data);
        setFilters(generateFilter(_data.filters))
        setExtraFilter(_data.extraFilter)
        setColumns(_data.columns)
        setModalFilterGet(false)
        setFilterFromApply(_data.filters)
        getData([...generateFilter(_data.filters), ..._data.extraFilter], _data.columns)
    }
    
    return (
        <>
            <Search
                handleFilter={handleFilter}
                title="Cheque Register Report"
                setModalFilter={setModalFilterSave}
                getModalFilterGet={setModalFilterGet}
                filterFromApply={filterFromApply}
            />
            {
                allData?.length > 0 && <Card>
                    <CardBody style={{ overflowX: 'scroll' }}>
                        <Table
                            rows={allData}
                            columns={columns}
                            onSearch={false}
                            fetchSelectedPage={null}
                            changeColumn={changeColumn}
                            handleChangeColumn={setChangeColumn}
                            updateColumns={updateColumns}
                            setSort={setSort}
                            sort={sort}
                            exportExcel={() => { }}
                            modalFilter={modalFilter}
                            setModalFilter={setModalFilter}
                            extraFilter={extraFilter}
                            updateExtraFilter={updateExtraFilter}
                            decimalArr={["hours_worked", "payroll_amount", "payroll_regular_hours", "payroll_holiday_hours"]}
                            isLastRowFooter={true}
                        />
                    </CardBody>
                </Card>
            }
            {
                allData?.length === 0 && <EmptyComponent />
            }
            {
                modalFilterSave
                    ?
                    <ModalLayout
                        modal={true}
                        toggle={() => setModalFilterSave(null)}
                        title={'Save Filter'}
                        size="md"
                    >
                        <SaveFilter
                            saveFilter={saveFilterData}
                        />
                    </ModalLayout>
                    : null
            }
            {
                modalFilterGet
                    ?
                    <ModalLayout
                        modal={true}
                        toggle={() => setModalFilterGet(null)}
                        title={'View Filter'}
                        size="lg"
                    >
                        <GetFilter
                            applyFilter={applyFilter}
                            reportType={"invoice_summary"}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    );

}

export default connect(null, { setBreadcrumbItems })(OrderReport);;