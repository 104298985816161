import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RateSetup from "../../../component/RateSetup"
import ModalRateCard from "../../../component/RateSetup/addRateCard";
import { postAdminAPI } from '../../../APICall/index';
import { GET_BILL_RATES, GET_ALL_SHIFT, ADD_UPDATE_BILL_RATES, DELETE_BILL_RATES } from '../../../APICall/urls'
import { generateCommonJsonActive, generateObject } from '../../../Utils/arrayFunction';

import { setBreadcrumbItems } from '../../../store/Breadcrumb/actions';
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'
import ConfirmModal from "../../../component/confirm/confirmForOrder";
import { getSelectedRateIds } from '../../../Utils/shifts'
import { COPY_BILL_RATE } from '../../../APICall/urls/settings';
import CopyClient from '../../../component/copyClient'


const BillingRates = () => {
    const dispatch = useDispatch();
    const [breadCrumbItems, setBreadCrumbItem] = useState([
        { title: "Client", link: "#" },
        { title: "BillRates", link: "#" },
    ]);
    const [modal, setModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [client, setClient] = useState(0);

    const [allRates, setAllRates] = useState([])
    const [allShifts, setAllShifts] = useState([])
    const [addModal, setAddModal] = useState([])

    const [shiftSelected, setShiftSelected] = useState(null)
    const [deleteRate, setDeleteRate] = useState(null)
    const [selectedRate, setSelectedRate] = useState(null)
    const [copyClient, setCopyClinet] = useState(false)
    const [finalRates, setFinalRates] = useState([])

    useEffect(() => {
        dispatch(setBreadcrumbItems("BillRates", breadCrumbItems));
        let client = localStorage.getItem("RNPlusClient")
        if(client && parseInt(client) > 0){
            setClient(client)
            getAPI(client)
        }
        else
            notificationStatus({status:false, message:"Please select client to view bill rates"})
    }, []);

    const basicDetails = useSelector((s) => {
        return s.BasicDetails;
    })

    useEffect(() => {
        if (allShifts && allShifts.length > 0) {
            getRates(client)
        }
    }, [allShifts])

    const getAPI = async (client) => {
        await getAllShifts(client)
        setSelected(null)
    }


    const getAllShifts = async (client) => {
        let data = {
            "client": client
        };
        let result = await postAdminAPI(GET_ALL_SHIFT, data)
        if (result.status) {
            //Get only 8.5 hours shifts
            let _allShifts = result.data.filter((a) => a.hours < 9);
            // console.log(_allShifts);
            _allShifts = generateObject(_allShifts)
            setAllShifts(_allShifts)
        }

    }


    const getRates = async (client) => {
        // console.log(client, allShifts)
        let result = await postAdminAPI(GET_BILL_RATES, { client_id: client })
        // console.log(result);
        if (result) {
            // console.log(result.data)
            let _allRates = generateCommonJsonActive(result.data, allShifts);
            // console.log(_allRates)
            setAllRates(_allRates)
        }
    }

    const addRates = () => {
        setModal(true);
        setSelectedRate(null)
        setAddModal(true);
    }


    const modifyRates = (s) => {
        setAddModal(false);
        setModal(true);
        setSelectedRate(s)
    }
    const onDeleteBtnClick = (c, i) => {
        setDeleteRate(c)
    }
    const deleteRates = async () => {
        notiflixLoaderStart();
        // console.log(selected);
        let result = await postAdminAPI(DELETE_BILL_RATES, {
            "id": selected.bill_rate_id,
            "deleted": 1
        })
        // console.log(result)
        if (result) {
            notificationStatus(result)
            if (result.status) {
                getAPI(client)
            }
            setDeleteRate(null)
        }
    }

    const handleSelected = (id) => {
        setSelected(id ? { ...id } : null)
    }

    const handleShiftSelected = (id) => {
        setShiftSelected(id)
    }

    const handleSubmit = async (data) => {
        let result = await postAdminAPI(ADD_UPDATE_BILL_RATES, data)
        // console.log(result)
        if (result) {
            notificationStatus(result)
            if (result.status) {
                getAPI(client)
                setModal(false);
                setAddModal(false);
                setSelected(null)
            }
        }
    }

    const handleSubmitCopy = async (clientIds, futureDate) => {
        console.log(finalRates);
        let ids = getSelectedRateIds(finalRates)
        console.log(ids);
        if (ids.length) {
            let data = {
                "ids": [...ids],
                "clientIds": [...clientIds],
                tableName: "client_bill_rate",
                rateType: "bill",
                srcClientId: client,
                // futureDate: futureDate
            }
            notiflixLoaderStart()
            let res = await postAdminAPI(COPY_BILL_RATE, data)
            if (res.status) {
                setCopyClinet(false)
                notificationStatus({ status: true, message: "Success" })
            }
            else
                notificationStatus({ status: false, message: "Failure" })
        }
    }

    console.log(allRates);
    return (
        <div>
            <RateSetup
                addRates={addRates}
                modifyRates={modifyRates}
                deleteRates={onDeleteBtnClick}
                selected={selected}
                handleSelected={handleSelected}
                handleShiftSelected={handleShiftSelected}
                shiftSelected={shiftSelected}
                allRates={allRates}

                id="ctr_type"

                copyClent={() => setCopyClinet(true)}
                setAllRates={setFinalRates}
            />
            {
                modal
                    ?
                    <ModalRateCard
                        isOpen={modal}
                        handleClose={() => setModal(false)}
                        selected={addModal ? null : selectedRate}
                        handleSubmit={handleSubmit}
                        contType={basicDetails.allUniqueContractorTypes}
                        shiftSelected={shiftSelected}
                        futureNotRequired={true}
                    />
                    : null
            }
            {
                deleteRate
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to delete this rate?",
                                proceed: true
                            }

                        }
                        title="Delete rate"
                        toggle={() => setDeleteRate(null)}
                        click={deleteRates}
                    />
                    : null
            }
            {
                copyClient && <CopyClient
                    handleClose={() => setCopyClinet(false)}
                    title="Copy Bill Rare"
                    isDateRequired={true}
                    handleSubmit={handleSubmitCopy}
                />
            }
        </div>
    );
};
export default BillingRates;
