import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkLogin, clearErrorLogin, clearError } from '../../store/actions';
import logodark from "../../assets/images/RN_logo.png";

class Pageslogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.checkLogin(this.state.username, this.state.password, this.props.history);
    }

    handleChange = (e) => {
        // console.log(e.target.value);
        this.setState(e.target.value)
    }

    componentDidMount() {
        this.props.clearErrorLogin();
        this.props.clearError();
    }

    render() {
        return (

            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" lg="6" xl="5">
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <h3 className="text-center mt-4">
                                        <Link to="\" className="logo logo-admin"><img src={logodark} height="85" alt="logo" /></Link>
                                    </h3>
                                    <div className="p-3">
                                        <h4 className="text-muted font-size-18 mb-1 text-center">Welcome Back !</h4>

                                        {/* {this.props.user && <Alert color="success">
                                                Your Login is successfull.</Alert>} */}

                                        {this.props.loginError && <Alert color="danger">
                                            {this.props.loginError}</Alert>}
                                        <form className="form-horizontal mt-4" onSubmit={this.handleSubmit}>

                                            <label htmlFor="username">Username</label>
                                            <input
                                                className="form-control" name="username"
                                                onChange={(e) => this.setState({ username: e.target.value })}
                                                placeholder="Enter Email"
                                                value={this.state.username} 
                                                required
                                                type="email"
                                            />



                                            <label htmlFor="userpassword" className='mt-2'>Password</label>
                                            <input className="form-control" name="password" type="password" value={this.state.password}
                                                onChange={(e) => this.setState({ password: e.target.value })}
                                                placeholder="Enter password" required />

                                            <div className="form-group row mt-4">
                                                {/* <Col xs="6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                        </div>
                                                    </Col> */}
                                                <Col xs="12" className="text-center">
                                                    <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                </Col>
                                            </div>
                                            {/* <div className="form-group mb-0 row">
                                                    <Col xs="12" className="mt-4">
                                                        <Link to="/forget-password" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                                    </Col>
                                                </div> */}
                                        </form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">

                                <p>© 2023 RNPlus. Crafted with <i className="mdi mdi-heart text-danger"></i> by Codebucket Solutions</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }
}

const mapStatetoProps = state => {
    const { user, loginError, loading } = state.Login;
    return { user, loginError, loading };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, clearErrorLogin, clearError })(Pageslogin));




