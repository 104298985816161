import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Required from "../../../../component/Required/star";
import AllStates from '../../../../Utils/Variables/state';
import AllCountry from '../../../../Utils/Variables/country';
import { generateCellNumber, generateSSNNumber, isNumeric } from '../../../../shared/numberFormat';
import DatePicker from "../../../../component/datePicker/datePicker"
import ConfirmModal from "../../../../component/confirm/confirmForOrder";

const General = (props) => {

    const [contractorType, setContractorType] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)

    const [basic, setBasic] = useState({
        fName: '',
        lName: '',
        add1: '',
        add2: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
        ssn: '',
        eeo: '',
        vet: '',
        type: '',
        gender: 'M',
        dob: '',
        dateApplied: '',
        dateHiring: '',
        inactive: 0,
        dateInactive: '',
        inactiveReason: '',

        mob1Code: 1,
        mob1: '',
        pin: '',
        mob1Desc: '',
        mob2Code: 1,
        mob2: '',
        mob2Desc: '',
        mob3Code: 1,
        mob3: '',
        mob3Desc: '',
        email1: '',
        email2: '',
        refBy: '',
        refType: '',

        terminate: 0,
        dateTerminate: '',
        terminateReason: '',
        password: '',

        modBy: '',
        modOn: '',


    })

    useEffect(() => {
        if (props.contractorDetails && Object.keys(props.contractorDetails).length > 0) {
            // console.log(props.contractorDetails);
            let _apiData = { ...props.contractorDetails };
            let _basic = { ...basic };
            _basic.fName = _apiData.f_name;
            _basic.lName = _apiData.l_name;
            _basic.add1 = _apiData.add_1;
            _basic.add2 = _apiData.add_2;
            _basic.city = _apiData.city;
            _basic.state = _apiData.state;
            _basic.zip = _apiData.zip;
            _basic.country = _apiData.country;
            _basic.ssn = _apiData.ssn;
            _basic.eeo = _apiData.eeo;
            _basic.vet = _apiData.vet;
            _basic.type = _apiData.type;
            _basic.gender = _apiData.gender;
            _basic.dob = _apiData.dob;
            _basic.dateApplied = _apiData.date_applied;
            _basic.dateHiring = _apiData.date_hiring;
            _basic.inactive = _apiData.inactive;
            _basic.dateInactive = _apiData.date_inactive;
            _basic.inactiveReason = _apiData.inactive_reason;

            _basic.mob1Code = _apiData.mob1_code;
            _basic.mob1 = _apiData.mob1;
            _basic.pin = _apiData.permanent_zip;
            _basic.mob1Desc = _apiData.mob1_desc;
            _basic.mob2Code = _apiData.mob2_code;
            _basic.mob2 = _apiData.mob2;
            _basic.mob2Desc = _apiData.mob2_desc;
            _basic.mob3Code = _apiData.mob3_code;
            _basic.mob3 = _apiData.mob3;
            _basic.mob3Desc = _apiData.mob3_code;
            _basic.email1 = _apiData.email1;
            _basic.email2 = _apiData.email2;
            _basic.refBy = _apiData.ref_by;
            _basic.refType = _apiData.ref_type;

            _basic.terminate = _apiData.terminate;
            _basic.dateTerminate = _apiData.date_terminate;
            _basic.terminateReason = _apiData.terminate_reason;

            _basic.modBy = _apiData.mod_by;
            _basic.modOn = _apiData.mod_on;
            _basic.password = _apiData.password;

            _basic.id = _apiData.id;
            setBasic(_basic);

        }

    }, [props.contractorDetails])


    useEffect(() => {
        if (props.basicDetails.allContractorTypes && props.basicDetails.allContractorTypes.length > 0) {
            setContractorType(props.basicDetails.allContractorTypes)
        }
    }, [props.basicDetails])


    const handleChange = (e, state) => {
        let _basic = { ...basic };
        console.log(state);
        let value = e?.target?.value;
        if (state == "mob1" || state == "mob2" || state == "mob3") {
            if(isNumeric(value) )
                value = generateCellNumber(value)
            else
                value = _basic[state]
        }
        if (state == "ssn") {
            value = generateSSNNumber(value)
        }
        if (state === "dob" || state === "dateApplied" || state === "dateHiring" || state === "dateInactive" || state === "dateTerminate") {
            value = e == "Invalid date" ? "" : e;
        }
        // console.log(state, _basic[state])
        if (state === "inactive" || state === "terminated") {
            _basic[state] = parseInt(_basic[state]) === 1 ? 0 : 1;
        }
        else {
            _basic[state] = value;
        }
        setBasic(_basic);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(basic);
        props.modifyContractorDetails(basic)
    }

    const onDeleteModalClick = () => {
        setDeleteModal(true)
    }

    const onDeleteClient = () => {

    }

    const onAddClick = () => {
        localStorage.setItem("RNPlusContractor", 0)
        window.location.reload();
    }

    return (

        <form onSubmit={handleSubmit} style={{ fontSize: 14 }}>
            {/* Name */}
            <div className="row">
                <div className="col-md-2">
                    First Name<Required />
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        required
                        onChange={(e) => handleChange(e, "fName")}
                        value={basic.fName}
                    />
                </div>
                <div className="col-md-2">
                    Last Name<Required />
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        required
                        onChange={(e) => handleChange(e, "lName")}
                        value={basic.lName}
                    />
                </div>
            </div>
            {/* Address */}
            <div className="row">
                <div className="col-md-2">
                    Address With City<Required />
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Address 1"
                        required
                        onChange={(e) => handleChange(e, "add1")}
                        value={basic.add1}
                    />
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Address 2"
                        onChange={(e) => handleChange(e, "add2")}
                        value={basic.add2}
                    />
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        onChange={(e) => handleChange(e, "city")}
                        value={basic.city}
                    />
                </div>
            </div>
            {/* State */}
            <div className="row">
                <div className="col-md-2">
                    State ZIP
                </div>
                <div className="col-md-4">
                    <select
                        className="form-control"
                        onChange={(e) => handleChange(e, "state")}
                        value={basic.state}
                    >
                        <AllStates />
                    </select>
                </div>
                <div className="col-md-3">
                    <input
                        type="number"
                        className="form-control"
                        placeholder="ZIP"
                        onChange={(e) => handleChange(e, "zip")}
                        value={basic.zip}
                    />
                </div>
                <div className="col-md-3">
                    <select
                        className="form-control"
                        onChange={(e) => handleChange(e, "country")}
                        value={basic.country}
                    >
                        <AllCountry />
                    </select>
                </div>
            </div>
            {/* SSN */}
            <div className="row">
                <div className="col-md-1">
                    SSN<Required />
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="SSN"
                        required
                        onChange={(e) => handleChange(e, "ssn")}
                        value={basic.ssn}
                    />
                </div>
                <div className="col-md-1">
                    EEO
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="EEO"
                        onChange={(e) => handleChange(e, "eeo")}
                        value={basic.eeo}
                    />
                </div>
                <div className="col-md-1">
                    VET
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="VET"
                        onChange={(e) => handleChange(e, "vet")}
                        value={basic.vet}
                    />
                </div>
            </div>
            {/* Type */}
            <div className="row">
                <div className="col-md-1">
                    Type<Required />
                </div>
                <div className="col-md-3">
                    <select
                        className="form-control"
                        required
                        onChange={(e) => handleChange(e, "type")}
                        value={basic.type}
                        disabled={props.contractorDetails.id}
                    >
                        <option selected="" disabled="">Select Type</option>
                        {
                            contractorType.map((c, i) => (
                                <option value={c.id}>{c.code}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-md-1">
                    Gender
                </div>
                <div className="col-md-3">
                    <select
                        className="form-control"
                        onChange={(e) => handleChange(e, "gender")}
                        value={basic.gender}
                    >
                        <option value="M" >Male</option>
                        <option value="F" >Female</option>
                        <option value="O" >Others</option>
                    </select>
                </div>
                <div className="col-md-1">
                    DOB<Required />
                </div>
                <div className="col-md-3">
                    <DatePicker
                        type="date"
                        className="form-control"
                        date={basic.dob}
                        name="dob"
                        handleDateChange={(name, date) => handleChange(date, name)}
                    />

                    {/* <input
                            type="date"
                            className="form-control"
                            placeholder="dob"
                            required
                            onChange={(e) => handleChange(e, "dob")}
                            value={basic.dob}
                        /> */}
                </div>
            </div>
            {/* Type */}
            <div className="row">
                <div className="col-md-2">
                    Date Applied
                </div>
                <div className="col-md-4">

                    <DatePicker
                        type="date"
                        className="form-control"
                        date={basic.dateApplied}
                        name="dateApplied"
                        handleDateChange={(name, date) => handleChange(date, name)}
                    />
                    {/* <input
                            type="date"
                            className="form-control"
                            placeholder="Date Applied"
                            onChange={(e) => handleChange(e, "dateApplied")}
                            value={basic.dateApplied}
                        /> */}
                </div>
                <div className="col-md-2">
                    Date Hired
                </div>
                <div className="col-md-4">

                    <DatePicker
                        type="date"
                        className="form-control"
                        date={basic.dateHiring}
                        name="dateHiring"
                        handleDateChange={(name, date) => handleChange(date, name)}
                    />
                    {/* <input
                            type="date"
                            className="form-control"
                            placeholder="Date hire"
                            onChange={(e) => handleChange(e, "dateHiring")}
                            value={basic.dateHiring}
                        /> */}
                </div>
            </div>
            {/* Inactive */}
            <div className="row">
                <div className="col-md-2 d-flex">
                    {/* <label>Date Applied</label>
                    <input 
                        type="checkbox" 
                        style={{height:15}}
                        className="form-control"
                        onChange={(e) => handleChange(e, "inactive")}
                        value="1"
                        checked={ parseInt(basic.inactive) === 1 ? true  : false}
                    />  */}
                    <input
                        type="checkbox"
                        class="form-control w-auto"
                        value="1"
                        id="inactive"
                        onChange={(e) => handleChange(e, "inactive")}
                        checked={parseInt(basic.inactive) === 1 ? true : false}
                    />
                    <label class="pl-2" for="inactive" >Inactive</label>
                </div>
                <div className="col-md-1">
                    On Date
                </div>
                <div className="col-md-3">

                    <DatePicker
                        type="date"
                        className="form-control"
                        date={basic.dateInactive}
                        name="dateInactive"
                        handleDateChange={(name, date) => handleChange(date, name)}
                    />
                    {/* <input
                            type="date"
                            className="form-control"
                            placeholder="On Date"
                            onChange={(e) => handleChange(e, "dateInactive")}
                            value={basic.dateInactive}
                        /> */}
                </div>
                <div className="col-md-2">
                    Reason
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Reason"
                        onChange={(e) => handleChange(e, "inactiveReason")}
                        value={basic.inactiveReason}
                    />
                </div>
            </div>
            <hr />

            {/* cell no */}
            <div className="row">
                <div className="col-md-2">
                    Cell No<Required />
                </div>
                <div className="col-md-2">
                    <select
                        className="form-control"
                        onChange={(e) => handleChange(e, "mob1Code")}
                        value={basic.mob1Code}
                        required
                    >
                        <option selected disabled>Country Code</option>
                        <option value="1" >USA(+1)</option>
                        <option value="91" >India(+91)</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        required
                        placeholder="Cell Number"
                        onChange={(e) => handleChange(e, "mob1")}
                        value={basic.mob1}
                    />
                </div>
                {/* <div className="col-md-1">
                        <input
                            type="text"
                            className="form-control"
                            disabled
                            placeholder="PIN"
                            onChange={(e) => handleChange(e, "password")}
                            value={basic.password}
                        />
                    </div> */}
                <div className="col-md-1">
                    Description
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        onChange={(e) => handleChange(e, "mob1Desc")}
                        value={basic.mob1Desc}
                    />
                </div>
            </div>
            {/* Alternate 1 */}
            <div className="row">
                <div className="col-md-2">
                    Alternate No 1
                </div>
                <div className="col-md-2">
                    <select
                        className="form-control"
                        onChange={(e) => handleChange(e, "mob2Code")}
                        value={basic.mob2Code}
                    >
                        <option selected disabled>Country Code</option>
                        <option value="1" >USA(+1)</option>
                        <option value="91" >India(+91)</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Alternate No 1"
                        onChange={(e) => handleChange(e, "mob2")}
                        value={basic.mob2}
                    />
                </div>

                <div className="col-md-1">
                    Description
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        onChange={(e) => handleChange(e, "mob2Desc")}
                        value={basic.mob2Desc}
                    />
                </div>
            </div>
            {/* Alternate 2 */}
            <div className="row">
                <div className="col-md-2">
                    Alternate No 2
                </div>
                <div className="col-md-2">
                    <select
                        className="form-control"
                        onChange={(e) => handleChange(e, "mob3Code")}
                        value={basic.mob3Code}
                    >
                        <option selected disabled>Country Code</option>
                        <option value="1" >USA(+1)</option>
                        <option value="91" >India(+91)</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Alternate No 1"
                        onChange={(e) => handleChange(e, "mob3")}
                        value={basic.mob3}
                    />
                </div>

                <div className="col-md-1">
                    Description
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        onChange={(e) => handleChange(e, "mob3Desc")}
                        value={basic.mob3Desc}
                    />
                </div>
            </div>
            {/* Email */}
            <div className="row">
                <div className="col-md-2">
                    Email<Required />
                </div>
                <div className="col-md-4">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={(e) => handleChange(e, "email1")}
                        value={basic.email1}
                    />
                </div>
                <div className="col-md-2">
                    Other Email
                </div>
                <div className="col-md-4">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Other Email"
                        onChange={(e) => handleChange(e, "email2")}
                        value={basic.email2}
                    />
                </div>
            </div>
            {/* Email */}
            <div className="row">
                <div className="col-md-2">
                    Ref by
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Ref by"
                        onChange={(e) => handleChange(e, "refBy")}
                        value={basic.refBy}
                    />
                </div>
                <div className="col-md-2">
                    Ref Type
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder=" Ref Type"
                        onChange={(e) => handleChange(e, "refType")}
                        value={basic.refType}
                    />
                </div>
            </div>
            <hr />
            {/* Terminated */}
            <div className="row">
                <div className="col-md-2 d-flex">
                    <input type="checkbox"
                        class="form-control w-auto"
                        value="1"
                        onChange={(e) => handleChange(e, "terminate")}
                        id="terminate"
                        checked={parseInt(basic.terminate) === 1 ? true : false}
                    />
                    <label
                        htmlFor="terminate"
                        class="pl-2"  >Terminated</label>
                </div>
                <div className="col-md-1">
                    On Date
                </div>
                <div className="col-md-2">
                    <DatePicker
                        type="date"
                        className="form-control"
                        date={basic.dateTerminate}
                        name="dateTerminate"
                        handleDateChange={(name, date) => handleChange(date, name)}
                    />
                    {/* <input
                            type="date"
                            className="form-control"
                            placeholder="On Date"
                            onChange={(e) => handleChange(e, "dateTerminate")}
                            value={basic.dateTerminate}
                        /> */}
                </div>
                <div className="col-md-1">
                    Reason
                </div>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Reason"
                        onChange={(e) => handleChange(e, "terminateReason")}
                        value={basic.terminateReason}
                    />
                </div>
                <div className="col-md-1">
                    Pin
                </div>
                <div className="col-md-2">
                    <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        placeholder="Password"
                        onChange={(e) => handleChange(e, "password")}
                        value={basic.password}
                    />
                </div>
            </div>
            <hr />
            {/* Terminated */}
            <div className="row">
                <div className="col-md-2">
                    Last Modified By
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        placeholder="Last Modified By"
                        onChange={(e) => handleChange(e, "modBy")}
                        value={basic.modBy}
                    />
                </div>
                <div className="col-md-2">
                    On
                </div>
                <div className="col-md-4">
                    <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        placeholder="On"
                        onChange={(e) => handleChange(e, "modOn")}
                        value={basic.modOn}
                    />
                </div>
            </div>
            <div className="row mt-2 mb-4">
                <div className="col-md-12 text-center" style={{ position: "relative" }}>
                    <button
                        class="btn btn-outline-success"
                        type="submit"
                    >Save</button>
                    {
                        (props.contractorDetails && Object.keys(props.contractorDetails).length > 0) &&
                        <div style={{ position: "absolute", right: 10, bottom: 0 }}>
                            <button type="button" className="btn btn-outline-primary" onClick={onAddClick}>Add New Contractor</button> &nbsp;
                            <button type="button" className="btn btn-danger" onClick={onDeleteModalClick}>Delete Contractor</button>
                        </div>
                    }

                </div>
            </div>
            {
                deleteModal
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: <>
                                    There are <strong>XX confirmed shifts</strong> associated with this contractor. Upon deleting all the future shifts will affect.
                                </>,
                                proceed: true
                            }

                        }
                        title="Delete Contractor permanently"
                        toggle={() => setDeleteModal(null)}
                        click={onDeleteClient}
                    />
                    : null
            }
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps, {})(General);

