import React, { Component } from "react";
import { connect } from "react-redux";
import { dateStandard, dateArithmetic, dateDifference } from '../../../Utils/dateFunctions';
import { setBreadcrumbItems } from "../../../store/Breadcrumb/actions";
import DailyAvailability from "./DailyAvailability/dailyAvailability";
import AlwaysAvailable from "./AlwaysAvailable/alwaysAvailable";
import AvailabilityModal from "../../../component/Availability/AvailabilityPopup"
import Filter from "./Filter";

import { GET_ALL_DAILY_AVAILABILITY, GET_ALL_PERMANENT_AVAILABILITY, UPDATE_DAILY_AVAILABILITY, UPDATE_PERMANENT_AVAILABILITY, TOGGLE_PERMANENT_AVAILABILITY } from "../../../APICall/urls/contractor";
import { postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'

class Availability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadCrumbItems: [],
            activeTab: "Availability",
            startDate: null,
            openModal: false,
            selectedContractor: null,
            selectedContName: null,
            alwaysAvailable: true,

            alwaysAvailability: null,
            dailyAvailability: [],
            selected: {},
            selectedDate: null,
            selectedDayNum: null,
            isAlways: false,
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Contractor Setup", this.state.breadCrumbItems);

        let contractor = parseInt(localStorage.getItem("RNPlusContractor"))
        let allContractor = JSON.parse(localStorage.getItem("RNPlusAllContractor"))
        if (contractor) {
            // console.log(allContractor)
            let _selectedContName = allContractor.filter(a => a.id === contractor)
            // console.log(_selectedContName, contractor)
            this.setState({ selectedContName: _selectedContName[0].name, alwaysAvailable:_selectedContName[0].permanent_avail ? true : false  })
            this.setState({ selectedContractor: contractor })

            // console.log(contractor)
            let _today = dateStandard(new Date());
            this.setState({
                startDate: _today,
            })

            this.getAlwaysAvailable(contractor)
            this.getDailyAvailable(contractor, _today)
        }
        else{
            notificationStatus({status:false, message:"Please select contractor to view availability"})
        }
        
    }

    handleAlwaysAvailable = (e) => {
        //api call\
        console.log(e.target)
        if (e.target.checked) {
            this.toggleAlwaysAvailable(1)
        }
        else {
            this.toggleAlwaysAvailable(0)
        }
        this.setState({ alwaysAvailable: !this.state.alwaysAvailable })
    }

    toggleAlwaysAvailable = async (status) => {
        let data = {
            "ctr": this.state.selectedContractor,
            "perma": status
        }
        let result = await postAdminAPI(TOGGLE_PERMANENT_AVAILABILITY, data);
        console.log(result);
    }

    //Get data from
    getAlwaysAvailable = async (contractor) => {

        let result = await postAdminAPI(GET_ALL_PERMANENT_AVAILABILITY, { contractor: contractor })
        if (result.status) {
            this.setState({ alwaysAvailability: result.data })
        }
        // console.log(result);
    }
    getDailyAvailable = async (contractor, date) => {
        let data = {
            "contractor": contractor,
            "date": date,
            "limit": 28
        };
        let result = await postAdminAPI(GET_ALL_DAILY_AVAILABILITY, data)
        if (result.status) {
            this.setState({ dailyAvailability: result.data })
        }
        console.log(result);
    }
    handleDateChange = (type, date) => {
        console.log( date)
        this.getDailyAvailable(this.state.selectedContractor, date)
        this.setState({ startDate: date })
    }

    handleDateArithmetic = (count) => {
        // console.log(count)
        this.setState({
            startDate: dateArithmetic(this.state.startDate, count),
        })
    }

    handleModal = (allData, isOpen, dayNum, date) => {
        // console.log(allData, isOpen, dayNum, date)
        if (isOpen) {
            if (dayNum && dayNum >= 0) {
                // console.log(allData, isOpen, dayNum, date)
                this.setState({ selected: allData, selectedDayNum: dayNum, selectedDate: null })
            }
            else {
                // console.log(allData, isOpen, dayNum, date)
                this.setState({ selected: allData, selectedDate: date, dayNum: null })
            }
        }
        this.setState({ openModal: !this.state.openModal })
    }

    handleSubmit = async (data, date, dayNum) => {
        notiflixLoaderStart()
        let _finalData = {
            "contId": this.state.selectedContractor,
            "notAvailable": data.not_available.toString(),
            "day": data.day.toString(),
            "eve": data.eve.toString(),
            "night": data.night.toString(),
            "hours": data.hours,
            "note": data.note
        }
        if (data.id) {
            _finalData.id = data.id
        }

        let result;
        if (date) {
            _finalData.date = date
            result = await postAdminAPI(UPDATE_DAILY_AVAILABILITY, _finalData)
        }
        else {
            _finalData.dayNum = dayNum.toString();
            result = await postAdminAPI(UPDATE_PERMANENT_AVAILABILITY, _finalData)
        }
        console.log(_finalData)

        if (result) {
            notificationStatus(result)
            if (result.status) {
                this.handleModal()
                this.getAlwaysAvailable(this.state.selectedContractor)
                this.getDailyAvailable(this.state.selectedContractor, this.state.startDate)
            }
        }
    }

    render() {

        const { startDate, openModal, alwaysAvailable, alwaysAvailability, dailyAvailability, selected, selectedDate, selectedDayNum, selectedContName } = this.state;

        console.log(alwaysAvailability)

        return (
            <div>
                <Filter
                    startDate={startDate}
                    handleDateChange={this.handleDateChange}
                    handleDateArithmetic={this.handleDateArithmetic}
                    isAlways={alwaysAvailable}
                    handleAlwaysAvailable={this.handleAlwaysAvailable}
                />
                {
                    alwaysAvailable
                        ?
                        alwaysAvailability ?
                            <AlwaysAvailable
                                startDate={startDate}
                                handleModal={this.handleModal}
                                alwaysAvailability={alwaysAvailability}
                                dailyAvailability={dailyAvailability}
                            />
                            : null
                        :
                        <DailyAvailability
                            startDate={startDate}
                            showDate={true}
                            handleModal={this.handleModal}
                            dailyAvailability={dailyAvailability}
                        />

                }
                {

                    openModal
                        ?
                        <AvailabilityModal
                            isOpen={openModal}
                            handleClose={this.handleModal}
                            selected={selected}
                            selectedDate={selectedDate}
                            selectedDayNum={selectedDayNum}
                            selectedContName={selectedContName}
                            handleSubmit={this.handleSubmit}
                        />
                        : null
                }

            </div>
        )
    }
};

export default connect(null, { setBreadcrumbItems })(Availability);

