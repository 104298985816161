import React, { Component, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import { setBreadcrumbItems } from "../../../store/Breadcrumb/actions";

import { postAdminAPI, postAdminFormadataAPI, getAdminAPI } from '../../../APICall/index';
import {
    GET_ONE_CLIENT, ADD_UPDATE_ONE_CLIENT, GET_CLIENT_STATES,
    GET_CLIENT_USERS, ADD_CLIENT_USER, TOGGLE_CLIENT_USER, RESET_CLIENT_PASSWORD, DELETE_CLIENT_USER,
    GET_CLIENT_LOCATION, UPDATE_CLIENT_LOCATION,
    GET_ALL_SHIFT, GET_WORK_CODE, ADD_UPDATE_SHIFT, DELETE_SHIFT,
    ADD_UPDATE_HOLIDAY, DELETE_HOLIDAY, GET_HOLIDAY,
    SAVE_CLIENT_CONTACTS_DIRECTION, GET_CLIENT_CONTACTS_DIRECTION, GET_CLIENT_CONTACT, DELETE_CLIENT_CONTACT, UPDATE_CLIENT_CONTACT,
    ADD_CLIENT_COMMENT, GET_CLIENT_COMMENT,
    UPLOAD_CLIENT_FILE, GET_CLIENT_FILE, GET_CLIENT_FILE_SINGLE, DELETE_CLIENT_FILE, MAKE_COPY_FILE_CLIENT

} from '../../../APICall/urls';
import * as APIs from '../../../APICall/urls';
import { notiflixLoaderStart, notificationStatus, notiflixLoaderEnd } from '../../../component/Notiflix/notiflix'
import { updateClient } from "../../../helpers/authUtils"
import { dateSlashFormant } from "../../../Utils/dateFunctions";


import "./SetUp.scss";
import General from "../SetUp/SetupTags/General";
import Location from "../SetUp/SetupTags/Location";
import Users from "../SetUp/SetupTags/Users";
import Shifts from "../SetUp/SetupTags/Shifts";
import Holidays from "../SetUp/SetupTags/Holidays";
import Contacts from "../SetUp/SetupTags/Contacts";
import Comments from "../SetUp/SetupTags/Comments";
import Documents from "../SetUp/SetupTags/Documents";
import Orientation from "./SetupTags/Orientation"
import Area from "./SetupTags/Area"

const navTagTabs = [
    "General",
    "Location",
    "Users",
    "Shifts",
    "Holidays",
    "Contact's & Directions",
    "Comments",
    "Documents",
    "Orientation",
    "Area"
];

const SetUp = (props) => {
    const dispatch = useDispatch();
    const [breadCrumbItems, setBreadCrumbItem] = useState([]);
    const [activeTab, setActiveTab] = useState("General");
    const [client, setClient] = useState(0);
    const [state, setState] = useState([])
    //GENERAL
    const [clientData, setClientData] = useState({})
    //LOCATION
    const [location, setLocation] = useState({})
    //USERS
    const [allUsers, setAllUsers] = useState([])
    const [userModal, setUserModal] = useState(false)
    //SHIFTS
    const [allShifts, setAllShifts] = useState([])
    const [allWorkCode, setAllWorkCode] = useState([])
    //HOLIDAYS
    const [allHolidays, setAllHolidays] = useState([])
    //CONTACT
    const [allContactDirection, setAllContactDirection] = useState({})
    const [allContacts, setAllContacts] = useState([])
    //COMMENTS
    const [comments, setComments] = useState([])
    //FILES
    const [files, setFiles] = useState([])
    //AREA
    const [allArea, setAllArea] = useState([])
    const [areaModal, setAreaModal] = useState(false)

    useEffect(() => {
        let client = localStorage.getItem("RNPlusClient")
        // console.log(client)
        dispatch(setBreadcrumbItems("SetUp", breadCrumbItems));
        getStates();
        getWorkCode()
        if(client && parseInt(client)){
            setClient(client)
            getGeneralData(client);
            getUserData(client);
            getAllShifts(client);
            getLocation(client);
            getAllHoliday(client);
            getContactDirection(client);
            getContact(client);
            getComments(client)
            getDocuments(client)
            getAreaData(client)
        }
        
    }, []);

    // console.log(client);

    const toggle = (tab) => {
        return activeTab !== tab ? setActiveTab(tab) : "";
    };

    const getStates = async () => {
        let result = await getAdminAPI(GET_CLIENT_STATES)
        setState(result ? result : []);
    }

    //-----------------General----------------
    const getGeneralData = async (client) => {

        let data = {
            "id": client
        };
        let result = await postAdminAPI(GET_ONE_CLIENT, data)
        if (result) {
            setClientData(result.data)
        }
    }


    const updateGeneralData = async (data) => {
        notiflixLoaderStart()
        if (client && client > 0) {
            data = {
                ...data,
                "id": client
            };
        }
        else {
            delete data.id;
        }
        let result = await postAdminAPI(ADD_UPDATE_ONE_CLIENT, data)

        if (result.status) {
            notificationStatus(result)
            getGeneralData(client)
            if (client && parseInt(client) > 0) {
                getAllClients(client);
            }
            else {
                getAllClients(result.data);
            }
        }
        else {
            notificationStatus(result)
        }
    }

    const getAllClients = async (clntId) => {
        let data = await postAdminAPI('/client/get-name', {})

        updateClient(data.data, clntId)
        window.location.reload()
    }
    //--------------------Location----------------

    const getLocation = async (clntId) => {
        let postData = {
            "client": clntId,
        }

        let data = await postAdminAPI(GET_CLIENT_LOCATION, postData);
        if (data.status) {
            setLocation(data.data[0])
        }

    }

    const updateLocation = async (lat, lng) => {
        notiflixLoaderStart()
        let postData = {
            "client": client,
            "lat": lat,
            "long": lng
        }

        let data = await postAdminAPI(UPDATE_CLIENT_LOCATION, postData);
        notificationStatus(data)
        if (data.status) {
            getLocation(client)
        }

    }

    //------------------Users---------------
    const getUserData = async (client) => {
        let data = {
            "client": client
        };
        let result = await postAdminAPI(GET_CLIENT_USERS, data)
        if (result.status) {
            setAllUsers(result.data)
        }
    }

    const addUserData = async (data) => {
        notiflixLoaderStart()
        data = {
            ...data,
            "clntId": client
        };
        let result = await postAdminAPI(ADD_CLIENT_USER, data)
        notificationStatus(result)
        if (result.status) {
            getUserData(client)
            setUserModal(false)
        }
    }

    const toggleUser = async (data) => {
        notiflixLoaderStart()
        let result = await postAdminAPI(TOGGLE_CLIENT_USER, data)
        if (result.status) {
            getUserData(client)
            notiflixLoaderEnd()
        }
    }

    const deleteUser = async (data, callback) => {
        notiflixLoaderStart()
        let result = await postAdminAPI(DELETE_CLIENT_USER, data)
        notificationStatus(result)
        if (result.status) {
            getUserData(client)
            callback()
        }
    }

    const resetPasswordUser = async (data) => {
        notiflixLoaderStart()
        let result = await postAdminAPI(RESET_CLIENT_PASSWORD, data)
        if (result.status) {
            getUserData(client)
            notiflixLoaderEnd()
        }
    }

    //------------------Shifts------------------

    const getAllShifts = async (client) => {
        let data = {
            "client": client
        };
        let result = await postAdminAPI(GET_ALL_SHIFT, data)
        if (result.status) {
            setAllShifts(result.data)
        }
    }

    const getWorkCode = async () => {
        let result = await postAdminAPI(GET_WORK_CODE, {})
        if (result.status) {
            setAllWorkCode(result.data)
        }
    }

    const deleteShifts = async (data) => {
        notiflixLoaderStart()
        let result = await postAdminAPI(DELETE_SHIFT, data)
        notificationStatus(result)
        if (result.status) {
            getAllShifts(client)
        }
    }

    const updateShifts = async (shifts) => {
        notiflixLoaderStart()
        let _allShifts = []
        if (shifts && shifts.length > 0) {
            shifts.forEach(h => {
                let shift = {
                    "name": h.name,
                    "startTime": h.start_time,
                    "endTime": h.end_time,
                    "type": h.type,
                    "color": h.color,
                    "isActive": h.is_active,
                    "workCode": h.work_code,
                    "client": client
                }
                if (h.id) {
                    shift = {
                        ...shift,
                        id: h.id
                    }
                }
                _allShifts.push(shift)
            })

        }
        let result = await postAdminAPI(ADD_UPDATE_SHIFT, _allShifts)
        notificationStatus(result)
        if (result.status) {
            getAllShifts(client)
        }
    }

    //--------------Holidays----------------------

    const getAllHoliday = async (client) => {
        console.log(client);
        let data = {
            "client_id": client
        };
        let result = await postAdminAPI(GET_HOLIDAY, data)
        if (result.status) {
            setAllHolidays(result.data)
        }
    }

    const deleteHoliday = async (id) => {
        // console.log(client);
        notiflixLoaderStart()
        let data = {
            "id": id,
        };
        let result = await postAdminAPI(DELETE_HOLIDAY, data)
        notificationStatus(result)
        if (result.status) {
            getAllHoliday(client)
        }
    }

    const updateHoliday = async (holiday) => {
        notiflixLoaderStart()
        let _allHoliday = []
        if (holiday && holiday.length > 0) {
            holiday.forEach(h => {
                let holiday = {
                    "client": client,
                    "name": h.name,
                    "startDate": dateSlashFormant(h.sDate) + " " + h.sTime,
                    "endDate": dateSlashFormant(h.eDate) + " " + h.eTime,
                    "clientBillFactor": h.client_bill_factor,
                    "clientPayFactor": h.client_pay_factor,
                    "isActive": h.is_active,
                    "isRecurring": h.is_recurring
                }
                if (h.id) {
                    holiday = {
                        ...holiday,
                        id: h.id
                    }
                }
                _allHoliday.push(holiday)
            })

        }
        let result = await postAdminAPI(ADD_UPDATE_HOLIDAY, _allHoliday)
        notificationStatus(result)
        if (result.status) {
            getAllHoliday(client)
        }
    }
    //----------------Contact and direction---------------

    const getContactDirection = async (client) => {
        let data = {
            "client_id": client
        };
        let result = await postAdminAPI(GET_CLIENT_CONTACTS_DIRECTION, data)
        if (result.status) {
            // console.log(result.data)
            setAllContactDirection(result.data)
        }
    }

    const updateContactDirection = async (contactData) => {
        notiflixLoaderStart()
        let data = {
            client: client,
            ...contactData
        }
        let result = await postAdminAPI(SAVE_CLIENT_CONTACTS_DIRECTION, data)
        notificationStatus(result)
        if (result.status) {
            getContactDirection(client)
        }
    }



    //-----------User Contact--------------
    const getContact = async (client) => {
        let data = {
            "client_id": client
        };
        let result = await postAdminAPI(GET_CLIENT_CONTACT, data)
        if (result.status) {
            setAllContacts(result.data)
        }
    }

    const deleteContacts = async (id) => {
        notiflixLoaderStart()
        let data = {
            "cont_id": id,
            "active": 0
        }
        let result = await postAdminAPI(DELETE_CLIENT_CONTACT, data)
        notificationStatus(result)
        if (result.status) {
            getContact(client)
        }
    }

    const updateContacts = async (data) => {
        notiflixLoaderStart()
        let result = await postAdminAPI(UPDATE_CLIENT_CONTACT, data)
        notificationStatus(result)
        if (result.status) {
            getContact(client)
        }
    }



    //----------------Comments----------------------------
    const getComments = async (client) => {
        notiflixLoaderStart()
        let data = {
            "client": client
        };
        let result = await postAdminAPI(GET_CLIENT_COMMENT, data)
        if (result.status) {
            setComments(result.data)
        }
        notiflixLoaderEnd()
    }

    const addComments = async (data) => {
        data = {
            ...data,
            "client": client
        };
        let result = await postAdminAPI(ADD_CLIENT_COMMENT, data)
        if (result.status) {
            getComments(client)
        }
    }
    //--Documents
    const getDocuments = async (client) => {
        let data = {
            "client": client
        };
        let result = await postAdminAPI(GET_CLIENT_FILE, data)
        if (result.status) {
            setFiles(result.data)
        }
    }

    const deleteDocuments = async (data, callback) => {
        notiflixLoaderStart()
        let result = await postAdminAPI(DELETE_CLIENT_FILE, data)
        if (result.status) {
            notificationStatus(result)
            getDocuments(client)
            callback()
        }
    }

    const uploadDocuments = async (data) => {
        notiflixLoaderStart()
        let formData = new FormData();
        formData.append("client", client)
        formData.append("file", data)
        let result = await postAdminFormadataAPI(UPLOAD_CLIENT_FILE, formData)
        if (result) {
            // console.log(result.data)
            notificationStatus({
                status: true,
                message: "Document uploaded successfully."
            })
            if (result)
                getDocuments(client)
        }
        else {
            notificationStatus({
                status: false,
                message: "Something went wrong.."
            })
        }
    }

    //------------------Area---------------
    const getAreaData = async (client) => {
        let data = {
            "clientId": client
        };
        let result = await postAdminAPI(APIs.GET_AREA, data)
        if (result.status) {
            setAllArea(result.data)
        }
    }

    const addArea = async (data) => {
        notiflixLoaderStart()
        let _data = {
            data: data,
            "clientId": client
        };
        let result = await postAdminAPI(APIs.ADD_AREA, _data)
        notificationStatus(result)
        if (result.status) {
            getAreaData(client)
            setAreaModal(false)
        }
    }

    const deleteArea = async (data, callback) => {
        notiflixLoaderStart()
        let _data = {
            data: [{
                "id": data.id,
                "name": data.name,
                "status": "INACTIVE"
            }],
            "clientId": client
        };
        let result = await postAdminAPI(APIs.ADD_AREA, _data)
        notificationStatus(result)
        if (result.status) {
            getAreaData(client)
            callback()
        }
    }


    // console.log(allArea);


    return (
        <div className={`card`}>

            <div className={`modal-body-container mt-2`}>
                <Nav tabs>
                    {client > 0 && navTagTabs.map((tabTag) => {
                        if (client > 0) {
                            return (
                                <NavItem>
                                    <NavLink
                                        onClick={() => toggle(tabTag)}
                                        className={classnames({
                                            active: activeTab === tabTag,
                                        })}
                                    >
                                        {tabTag}
                                    </NavLink>
                                </NavItem>
                            );
                        }
                    })}
                    {
                        client == 0 ?
                            <NavItem>
                                <NavLink
                                    onClick={() => toggle("General")}
                                    className={classnames({
                                        active: activeTab === "General",
                                    })}
                                >
                                    {"General"}
                                </NavLink>
                            </NavItem>
                            : null
                    }
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={activeTab}>
                        {activeTab === "General" ? (
                            <General
                                clientData={clientData}
                                updateGeneralData={updateGeneralData}
                                allState={state}
                                allWorkCode={allWorkCode}
                            />
                        ) : activeTab === "Location" ? (
                            <Location
                                location={location}
                                updateLocation={updateLocation}
                            />
                        ) : activeTab === "Users" ? (
                            <Users
                                allUsers={allUsers}
                                addUserData={addUserData}
                                toggleUser={toggleUser}
                                deleteUser={deleteUser}
                                resetPasswordUser={resetPasswordUser}
                                userModal={userModal}
                                setUserModal={setUserModal}
                            />
                        ) : activeTab === "Shifts" ? (
                            <Shifts
                                allShifts={allShifts}
                                deleteShifts={deleteShifts}
                                updateShifts={updateShifts}
                                allWorkCode={allWorkCode}
                                clientId={client}
                            />
                        ) : activeTab === "Holidays" ? (
                            <Holidays
                                allHolidays={allHolidays}
                                deleteHoliday={deleteHoliday}
                                updateHoliday={updateHoliday}
                                clientId={client}

                            />
                        ) : activeTab === "Contact's & Directions" ? (
                            <Contacts
                                allContactDirection={allContactDirection}
                                allContacts={allContacts}
                                updateContactDirection={updateContactDirection}
                                client={client}
                                deleteContacts={deleteContacts}
                                updateContacts={updateContacts}
                            />
                        ) : activeTab === "Comments" ? (
                            <Comments
                                addComments={addComments}
                                comments={comments}
                            />
                        ) : activeTab === "Documents" ? (
                            <Documents
                                files={files}
                                deleteDocuments={deleteDocuments}
                                uploadDocuments={uploadDocuments}
                            />
                        ) : activeTab === "Orientation" ? (
                            <Orientation

                            />
                        ) : activeTab === "Area" ? (
                            <Area
                                addArea={addArea}
                                deleteArea={deleteArea}
                                setAreaModal={setAreaModal}
                                areaModal={areaModal}
                                allAreas={allArea}
                            />
                        ) : (
                            ""
                        )}
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
};
export default SetUp;
