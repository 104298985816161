import React, { Component } from "react";
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../../store/Breadcrumb/actions";
import Filter from "./Filter";

import { ADD_DNR, GET_DNR } from "../../../APICall/urls/contractor";
import { postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'

class Availability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadCrumbItems: [],
            allClient: [],
            selectedClient: [],
            selectedContractor: 0,
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Contractor DNR", this.state.breadCrumbItems);
        let contractor = parseInt(localStorage.getItem("RNPlusContractor"))
        let allClient = JSON.parse(localStorage.getItem("RNPlusAllClient"))
        // console.log(allClient);
        if (contractor && parseInt(contractor) > 0) {
            this.setState({ selectedContractor: contractor })
            if (allClient) {
                this.setState({ allClient: allClient })
            }
            this.getData(contractor, allClient)
        }
        else {
            notificationStatus({ status: false, message: "Please select contractor to mark as DNR" })
        }
    }

    getData = async (selectedContractor, allClient) => {
        let result = await postAdminAPI(GET_DNR, { id: selectedContractor })
        if (result.status && result.data.dnr) {
            let dnr = JSON.parse(result.data.dnr)
            let _data = [];
            // console.log(dnr, allClient)
            if (dnr.length > 0) {
                dnr.forEach((d) => {
                    let item = allClient.filter((c) => c.id == d)
                    if (item.length > 0) {
                        _data.push({
                            label: item[0].name,
                            value: item[0].id
                        })
                    }
                })
                // console.log(_data)
            }
            this.setState({ selectedClient: _data })
        }
    }
    handleSubmit = async (data) => {
        notiflixLoaderStart()
        let _finalData = {
            "facility": JSON.stringify(data),
            id: this.state.selectedContractor
        }
        let result = await postAdminAPI(ADD_DNR, _finalData)

        if (result) {
            notificationStatus(result)
            this.getData(this.state.selectedContractor, this.state.allClient)
        }
    }



    render() {
        // console.log(this.state.selectedClient)
        return (
            <div>
                <div>Please select the facility to mark as DNR</div>
                <Filter
                    options={this.state.allClient}
                    selected={this.state.selectedClient}
                    handleSubmit={this.handleSubmit}
                />
            </div>
        )
    }
};

export default connect(null, { setBreadcrumbItems })(Availability);

