import React, { useState, useEffect } from "react";

import AddressFrom from "../AddressForm/AddressForm";
import Required from "../../../../component/Required/star";
import { dateTimeBasicFormant } from "../../../../Utils/dateFunctions";
import ConfirmModal from "../../../../component/confirm/confirmForOrder";

const regions = ["region1", "region2"];
const subRegions = ["subRegion1", "subRegion2"];
const parents = ["parent1", "parent2"];

const checkBoxDivStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
};

const labelStyle = {
    marginBottom: "0px",
    cursor: "pointer",
};

const col12DivStyle = {
    display: "flex",
};

const General = (props) => {


    const [mailEmail, setMailEmail] = useState([""])
    const [deleteModal, setDeleteModal] = useState(false)

    const [state, setState] = useState({
        name: '',
        add_1: '',
        add_2: '',
        zip: '',
        city: '',
        state: '',
        bill_name: '',
        bill_add_1: '',
        bill_add_2: '',
        bill_zip: '',
        bill_city: '',
        bill_state: '',
        loginCode: 0,
        loginNo: 0,
        inactive: 0,
        invoice_attn_to: '',
        invoice_email: "",
        invoice_days: '30',
        taxable: 0,
        // mailing_email: '',
        fac_type: 1,
        region: '',
        subregion: '',
        parent: '',
        orient: 0,
        acc_no: '',
        abbr: ""
    })

    const [addCheck, setAddCheck] = useState(false)

    useEffect(() => {
        if (props.clientData && Object.keys(props.clientData).length > 0) {
            setState(props.clientData)
            setMailEmail(props.clientData?.mailing_email ? props.clientData.mailing_email.split(",") : [])
        }
    }, [props.clientData])

    const handleAddressCheck = (e) => {
        console.log(e.target.checked)
        setAddCheck(!addCheck)
        if (e.target.checked) {
            console.log({
                ...state,
                bill_name: state.name,
                bill_add_1: state.add_1,
                bill_add_2: state.add_2,
                bill_zip: state.zip,
                bill_city: state.city,
                bill_state: state.state,
            })
            console.log(state)
            setState({
                ...state,
                bill_name: state.name,
                bill_add_1: state.add_1,
                bill_add_2: state.add_2,
                bill_zip: state.zip,
                bill_city: state.city,
                bill_state: state.state,
            })
        }
        else {
            setState({
                ...state,
                bill_name: '',
                bill_add_1: '',
                bill_add_2: '',
                bill_zip: '',
                bill_city: '',
                bill_state: '',
            })
        }
    }

    const handleCheckbox = (e, type) => {
        let _state = { ...state };
        _state[type] = _state[type] == 1 ? 0 : 1;
        setState(_state);
    };



    const handleChange = (e, type) => {
        let _state = { ...state };
        _state[type] = e.target.value
        setState(_state);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let _state = {
            ...state,
            fac_type: state.fac_type ? parseInt(state.fac_type) : 1,
            mailing_email: mailEmail
        }
        console.log(_state)
        props.updateGeneralData(_state)
    }

    const handleAdd = () => {
        let _mailEmail = [...mailEmail]
        _mailEmail.push("")
        setMailEmail(_mailEmail)
    }

    const handleDelete = (i) => {
        let _mailEmail = [...mailEmail]
        _mailEmail.splice(i, 1)
        setMailEmail(_mailEmail)
    }

    const handleMailChange = (e, i) => {
        let _mailEmail = [...mailEmail]
        _mailEmail[i] = e.target.value;
        setMailEmail(_mailEmail)
    }

    const onDeleteModalClick = () => {
        setDeleteModal(true)
    }

    const onDeleteClient = () => {

    }

    const onAddClick = () => {
        localStorage.setItem("RNPlusClient", 0)
        window.location.reload();
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <AddressFrom
                    isBillingAddress={false}
                    name={{ name: "name", value: state.name }}
                    add1={{ name: "add_1", value: state.add_1 }}
                    add2={{ name: "add_2", value: state.add_2 }}
                    zip={{ name: "zip", value: state.zip }}
                    state={{ name: "state", value: state.state }}
                    city={{ name: "city", value: state.city }}
                    allState={props.allState}
                    handleChange={handleChange}

                />
                <div className={`row`}>
                    <div className={`col-2`} style={{ paddingLeft: 24 }}>Billing Address</div>
                    <div
                        className={`col-2 input-checkbox`}

                    >
                        <input
                            id={`same-as-above`}
                            type={`checkbox`}
                            checked={addCheck}
                            onChange={(e) => handleAddressCheck(e)}
                        />
                        <label
                            htmlFor={`same-as-above`}
                            className={"checkbox-label"}
                            style={{ marginBottom: "0px", cursor: "pointer" }}
                        >
                            Same As Above
                        </label>
                    </div>
                </div>
                {
                    <AddressFrom
                        isBillingAddressChecked={addCheck}
                        isBillingAddress={true}
                        name={{ name: "bill_name", value: state.bill_name }}
                        add1={{ name: "bill_add_1", value: state.bill_add_1 }}
                        add2={{ name: "bill_add_2", value: state.bill_add_2 }}
                        zip={{ name: "bill_zip", value: state.bill_zip }}
                        state={{ name: "bill_state", value: state.bill_state }}
                        city={{ name: "bill_city", value: state.bill_city }}
                        allState={props.allState}
                        handleChange={handleChange}

                    />
                }
                <div className={`row`}>
                    <div className={`col-12`} style={col12DivStyle}>
                        <div className={`col-2`}>Abbreviation</div>
                        <div className={`col-10`}>
                            <input
                                className={`form-control`}
                                placeholder={`Enter Client Abbreviation`}
                                value={state.abbr}
                                onChange={(e) => handleChange(e, "abbr")}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div className={`row`} style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <div className={`col-2`}>Facility Type</div>
                    <div className={`col-4`}>
                        <select
                            value={state.fac_type}
                            onChange={(e) => handleChange(e, "fac_type")}
                            className={`form-control`}
                        >
                            <option>Select Facility Type</option>
                            {
                                props.allWorkCode.map(s => (
                                    <option value={s.id}>{s.des}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className={`col-2 input-checkbox`} >
                        <input
                            type={`checkbox`}
                            id={`inactive`}
                            checked={state.inactive == 1 ? true : false}
                            onChange={(e) => handleCheckbox(e, "inactive")}
                        />
                        <label className="checkbox-label" htmlFor={`inactive`}>
                            Inactive
                        </label>
                    </div>
                    <div className={`col-2 input-checkbox`}>
                        <input
                            type={`checkbox`}
                            id={`orient`}
                            value={state.orient}
                            checked={state.orient == 1 ? true : false}
                            onChange={(e) => handleCheckbox(e, "orient")}
                        />
                        <label htmlFor={`orient`} className="checkbox-label">
                            Orientation Mandatory
                        </label>
                    </div>
                    <div className={`col-1`} style={checkBoxDivStyle}>
                        <input
                            className="form-control"
                            type={`text`}
                            value={props.clientData ? props.clientData.id : ""}
                            disabled
                        />
                    </div>

                </div>
                <div className={`row`}>
                    <div className={`col-12`} style={col12DivStyle}>
                        <div className={`col-2`}>Region</div>
                        <div className={`col-4`}>
                            <select
                                value={state.region}
                                className={`form-control`}
                                onChange={(e) => handleChange(e, "region")}
                            >
                                <option value="">Select Region</option>
                                {regions.map((region) => {
                                    return <option value={region}>{region}</option>;
                                })}
                            </select>
                        </div>
                        <div className={`col-2`}>Sub Region</div>
                        <div className={`col-4`}>
                            <select
                                value={state.subregion}
                                className={`form-control`}
                                onChange={(e) => handleChange(e, "subregion")}
                            >
                                <option value="">Select SubRegion</option>
                                {subRegions.map((subRegion) => {
                                    return <option value={subRegion}>{subRegion}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className={`row`}>
                    <div className={`col-12`} style={col12DivStyle}>
                        <div className={`col-2`}>Parent</div>
                        <div className={`col-10`}>
                            <select
                                value={state.parent}
                                className={`form-control`}
                                onChange={(e) => handleChange(e, "parent")}
                            >
                                <option value={``}>Select Parent </option>
                                {parents.map((parent) => {
                                    return <option value={parent}>{parent}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                <div className={`row`}>
                    <div className={`col-12`} style={col12DivStyle}>
                        <div className={`col-2`}>Making Invoice (Attn.To)</div>
                        <div className={`col-2`}>
                            <input
                                className={`form-control`}
                                value={state.invoice_attn_to}
                                onChange={(e) => handleChange(e, "invoice_attn_to")}
                            />
                        </div>
                        <div className={`col-1`}>Invoice email</div>
                        <div className={`col-3`}>
                            <input
                                type={`email`}
                                value={state.invoice_email}
                                onChange={(e) => handleChange(e, "invoice_email")}
                                className={`form-control`}
                            />
                        </div>
                        <div className={`col-2`}>Invoice Payment Terms <Required /></div>
                        <div className={`col-2`}>
                            <input
                                type={`number`}
                                required
                                value={state.invoice_days}
                                onChange={(e) => handleChange(e, "invoice_days")}
                                className={`form-control`}
                            />
                        </div>
                    </div>
                </div>

                <div className={`row`}>
                    <div className={`col-12`} style={col12DivStyle}>
                        <div className={`col-2`}>Acct/PO #</div>
                        <div className={`col-4`}>
                            <input
                                className={`form-control`} type={`number`}
                                value={state.acc_no}
                                onChange={(e) => handleChange(e, "acc_no")}
                            />
                        </div>
                        <div className={`col-2 input-checkbox`}>
                            <input
                                type={`checkbox`}
                                id={`taxable`}

                                checked={state.taxable && parseInt(state.taxable) == 1 ? true : false}
                                onChange={(e) => handleCheckbox(e, "taxable")}
                            />
                            <label className="checkbox-label" htmlFor={`taxable`}>
                                Taxable
                            </label>
                        </div>
                    </div>
                </div>

                <div className={`row`}>

                    {
                        mailEmail.map((m, i) => (
                            <div className={`col-12`} style={col12DivStyle}>
                                <div className={`col-2`}>Contact Email</div>
                                <div key={i} className={`col-9`}>
                                    <input
                                        className={`form-control`}
                                        placeholder={`Contact Email`}
                                        // value={state.mailing_email}
                                        value={m}
                                        type="email"
                                        required="true"
                                        onChange={(e) => handleMailChange(e, i)}
                                    />
                                </div>
                                <div key={i} onClick={() => handleDelete(i)} className={`col-1`}>
                                    <button type="button" class="btn btn-circle1 btn-outline-danger mb-2" id="del_shift" title="Delete"><i class="fas fa-trash"></i></button>
                                </div>
                            </div>
                        ))
                    }
                    <div className={`col-12`} style={col12DivStyle}>
                        <div className={`col-11`}>

                        </div>

                        <div onClick={() => handleAdd()} className={`col-1`}>
                            <button type="button" class="btn btn-circle1 btn-outline-primary mb-2" title="Add">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`row`}>
                    <div className={`col-12`} style={col12DivStyle}>
                        <div className={`col-2`}>Last Modified By</div>
                        <div className={`col-4`}>
                            <input
                                disabled
                                className={`form-control`}
                                value={state.mod_by}
                            />
                        </div>
                        <div className={`col-2`}>Last Modified On</div>
                        <div className={`col-4`}>
                            <input
                                value={dateTimeBasicFormant(state.mod_on)}
                                className={`form-control`}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center" style={{ position: "relative" }}>
                            <button className="btn btn-outline-success" type="submit">SAVE</button>
                            {
                                (props.clientData && Object.keys(props.clientData).length > 0) &&
                                <div style={{ position: "absolute", right: 10, bottom: 0 }}>
                                    <button type="button" className="btn btn-outline-primary" onClick={onAddClick}>Add New Client</button> &nbsp;
                                    <button type="button" className="btn btn-danger" onClick={onDeleteModalClick}>Delete Client</button>
                                </div>
                            }

                            {/* <button type="button" style={{ position: "absolute", right: "0" }} className="btn btn-danger" onClick={onDeleteModalClick}>Delete Client</button> */}
                        </div>
                    </div>

                </div>
                {
                    deleteModal
                        ?
                        <ConfirmModal
                            modal={
                                {
                                    message: <>
                                        There are <strong>XX confirmed shifts</strong> associated with this client. Upon deleting all the future shifts will cancelled.
                                    </>,
                                    proceed: true
                                }

                            }
                            title="Delete Client permanently"
                            toggle={() => setDeleteModal(null)}
                            click={onDeleteClient}
                        />
                        : null
                }
            </form>
        </>
    );
};
export default General;
