//CONTRACTOR
const initials = "/contractor/";
//SETUP
export const GET_ONE_CONTRACTOR = initials+"get-contractor";
export const ADD_UPDATE_ONE_CONTRACTOR = initials+"add-update-contractor"

export const GET_All_CRED_TYPE = initials+"get-credential-types";
export const GET_CONTRACTOR_CRED = initials+"get-cred-file-list";
export const ADD_CONTRACTOR_CRED = initials+"upload-cred-file";
export const DELETE_CONTRACTOR_CRED = initials+"delete-cred-file";

export const ADD_CONTRACTOR_COMMENT = initials+"add-comment"
export const GET_CONTRACTOR_COMMENT = initials+"get-comment"

export const UPLOAD_CONTRACTOR_FILE = initials+"upload-file";
export const GET_CONTRACTOR_FILE = initials+"get-file-list";
export const DELETE_CONTRACTOR_FILE = initials+"delete-file";
export const GET_CONTRACTOR_FILE_SINGLE = initials+"get-file";

export const GET_CONTRACTOR_CONTACT = initials+"get-contact";
export const ADD_CONTRACTOR_CONTACT = initials+"add-update-contact";
export const DELETE_CONTRACTOR_CONTACT = initials+"delete-contact";

export const ADD_UPDATE_CUSTOM = initials+"add-update-custom"
export const GET_CUSTOM = initials+"get-custom";

export const GET_STATES = "/client/get-states"
export const GET_POLICY = "/contractor/get-cred-cert-list";

//schedule
export const GET_SCHEDULE = "/order/get-mini-contractor";


//Availabilkity
export const GET_ALL_DAILY_AVAILABILITY = initials+"get-availability";
export const GET_ALL_PERMANENT_AVAILABILITY = initials+"get-perma-availability";
export const UPDATE_DAILY_AVAILABILITY = initials+"update-avail";
export const UPDATE_PERMANENT_AVAILABILITY = initials+"update-perma-avail";

export const TOGGLE_PERMANENT_AVAILABILITY = initials+"toggle-perma-availability"


//Pay rates
export const GET_PAY_RATE = "/payroll/get-contractor-rate";
export const UPDATE_PAY_RATE = "/payroll/add-update-contractor-rate";
export const DELETE_PAY_RATE = "/payroll/delete-contractor-rate"

//DNR
export const ADD_DNR = initials+"add-update-dnr";
export const GET_DNR = initials+"get-contractor";


//Payslip
export const ADD_PAYSLIP = initials+"add-update-payslip-data";
export const GET_PAYSLIP = initials+"get-payslip-data";

//Download
export const GET_CRED_FILE  = "/contractor/get-cred-file"

//
export const GET_NEW_CONTRACTOR = "/contractor/get-name" 
export const APPROVE_NEW_CONTRACTOR = "/contractor/approve"  
export const REJECT_NEW_CONTRACTOR = "/contractor/get-contractor"  






