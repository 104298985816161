import React, { Component } from "react";
import { connect } from "react-redux";
import { dateStandard, dateArithmetic, dateDifference } from '../../../Utils/dateFunctions';
import { setBreadcrumbItems } from "../../../store/Breadcrumb/actions";
import ContractorSchedule from "../../../component/ContractorSchedule";
import Filter from "./Filter";
import StaffingModal from "../../StaffingSheet/modalComponent";
import { GET_SCHEDULE, } from "../../../APICall/urls/contractor";
import { GET_ALL_SHIFT } from "../../../APICall/urls";

import { postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'
import { changeTzToNormal } from "../../../Utils/dateFunctions";

class Availability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadCrumbItems: [],
            startDate: null,
            openModal: false,

            selectedShift: null,
            selectedDate: null,
            selectedClient: null,
            selectedOrder: {},

            selectedContractor: null,
            allOrders: [],
            shifts: []
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Contractor Schedule", this.state.breadCrumbItems);
        let contractor = parseInt(localStorage.getItem("RNPlusContractor"));
        let client = parseInt(localStorage.getItem("RNPlusClient"));
        // console.log(contractor)
        if (contractor) {
            let _today = dateStandard(new Date());
            this.setState({
                ...this.state,
                startDate: _today, selectedContractor: contractor, selectedClient: client
            })
            this.getSchedule(_today, contractor)
        }
        else{
            notificationStatus({ status: false, message: "Please select contractor to view schedule" })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(prevState)
        if (prevProps.basicDetails && (prevProps.basicDetails.realtimeTimeStamp != this.props.basicDetails.realtimeTimeStamp) && this.state.selectedContractor) {
            this.getSchedule(this.state.startDate, this.state.selectedContractor);
        }

        if (this.state.startDate != prevState.startDate) {
            this.getSchedule(this.state.startDate, this.state.selectedContractor);
        }
    }


    handleDateChange = (type, date) => {
        // console.log(date)
        this.setState({ ...this.state,startDate: date })
    }

    handleDateArithmetic = (count) => {
        // console.log(count)
        this.setState({
            ...this.state,
            startDate: dateArithmetic(this.state.startDate, count),
        })
    }

    getSingleCardData = (shift, count, data) => {
        console.log(shift, count, data)

        let selectedDate = dateArithmetic(this.state.startDate, count)
        if (data && Object.keys(data).length > 0) {
            let start = changeTzToNormal(data.start_date);
            let end = changeTzToNormal(data.end_date);


            let shifts = {
                start_time: start.time + ":00",
                end_time: end.time + ":00",
                id: data.shift
            }

            let selectedOrder = {
                id: data.id,
                responses: null
            }
            this.setState({ ...this.state,selectedDate: selectedDate, openModal: true, selectedShift: shifts, selectedOrder: selectedOrder, selectedClient: data.client })
        }

        else {
            this.setState({ ...this.state,selectedDate: selectedDate, openModal: true, selectedShift:null, selectedOrder:{}, selectedClient:null })
        }




    }

    //get schedule
    getSchedule = async (start, ctr) => {
        let data = {
            "startDate": start,
            "endDate": dateArithmetic(start, 27),
            "ctr": ctr
        }
        // console.log(data);
        let result = await postAdminAPI(GET_SCHEDULE, data)
        if (result.status) {
            this.setState({ ...this.state,allOrders: result.data })
        }
    }

    handleModal = () => {

        this.setState({ ...this.state,openModal: !this.state.openModal })
    }

    updateShifts = () => {
        const { startDate, selectedContractor } = this.state;
        this.getSchedule(startDate, selectedContractor)
    }




    render() {

        const { startDate, openModal, selectedShift, selectedDate, selectedClient, selectedOrder, selectedContractor, allOrders } = this.state;

        return (
            <div>
                {
                    selectedContractor
                        ?
                        <>
                            <Filter
                                startDate={startDate}
                                handleDateChange={this.handleDateChange}
                                handleDateArithmetic={this.handleDateArithmetic}
                            />
                            <ContractorSchedule
                                startDate={startDate}
                                showDate={true}
                                numberOfRow={4}
                                handleModal={this.getSingleCardData}
                                allOrders={allOrders}
                                afterActionCalls={() =>this.getSchedule(this.state.startDate, this.state.selectedContractor)}
                            />
                        </>
                        : null
                }

                {
                    openModal && selectedContractor
                        ?
                        <StaffingModal
                            modal={openModal}
                            handleStaffingSheetModal={() => this.handleModal()}
                            selectedShift={selectedShift}
                            selectedDate={selectedDate}
                            selectedClient={this.state.selectedClient}
                            selectedOrder={selectedOrder}
                            updateAPI={this.updateShifts}
                            isContractor={true}
                            selectedContractor={selectedContractor}
                            shifts={this.state.shifts}


                        />
                        : null

                }
            </div>
        )
    }
};

const mapStateToProps = state => {

    return {
        basicDetails: state.BasicDetails
    }

}

export default connect(mapStateToProps, { setBreadcrumbItems })(Availability);

