import React, { useEffect, useState } from 'react';
import logicalOperator from "../../Utils/reportjson/logicalOperator.json"
import conditionalOperator from "../../Utils/reportjson/conditionalOperator.json"
import * as helper from "../../pages/Reports/Download/report/helper"

import "./styles.css"
const Table = (props) => {


    const [filters, setAllFilters] = useState([...props.filters])

    const [state, setState] = useState({
        column: "",
        operator: "",
        value: "",
        logical: "AND"
    })

    const updateState = (e) => {
        let data = {
            ...state,
            [e.target.name]: e.target.value
        }
        // if(e.target.name === "like"){
        //     data["value"] = "%" + data["value"] + "%"
        // }
        // else if(e.target.name === "value" && data["operator"]  === "like"){
        //    data["value"] = "%" + e.target.value + "%"
        // }
        setState(data)
    }

    const addFilter = () => {
        let _filters = [...filters]
        console.log(state);
        if (state.column && state.operator && state.value && state.logical) {
            if (state.operator === "like")
                _filters.push([state.column, state.operator, `%${state.value}%`, state.logical])
            else
                _filters.push([state.column, state.operator, state.value, state.logical])
            setAllFilters(_filters)
            setState({
                column: "",
                operator: "",
                value: "",
                logical: "AND"
            })
        }

    }
    const removeFilter = (index) => {
        let tempData = Array.from(filters);
        tempData.splice(index, 1);
        setAllFilters(tempData)
    }

    const updateColumns = () => {
        props.updateFilters(filters)
        props.toggle()
    }


    console.log(state);

    return (
        <div className="mdb-datatable dt-bootstrap4">
            <div className='row'>
                <div className='col-md-3'>
                    <select
                        className={"form-control"}
                        title={""}
                        value={state.column}
                        name="column"
                        onChange={(e) => updateState(e)}
                    >
                        <option>Select</option>
                        {
                            props.columns?.map((p, i) => (
                                p.isVisible && <option key={i} value={p.key}>{p.label}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-md-3'>
                    <select
                        className={"form-control"}
                        title={""}
                        value={state.operator}
                        name="operator"
                        onChange={(e) => updateState(e)}
                    >
                        <option>Select</option>
                        {
                            conditionalOperator?.map((p, i) => (
                                <option key={i} value={p.id}>{p.value}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-md-3'>
                    <input
                        className={"form-control"}
                        title={""}
                        placeholder='Value'
                        value={state.value}
                        name={"value"}
                        onChange={(e) => updateState(e)}
                    />

                </div>
                <div className='col-md-2'>
                    <select
                        className={"form-control"}
                        title={""}
                        value={state.logical}
                        name='logical'
                        onChange={(e) => updateState(e)}
                    >
                        <option>Logical</option>
                        {
                            logicalOperator?.map((p, i) => (
                                <option key={i} value={p.id}>{p.value}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-md-1 d-flex align-items-center justify-content-center'>
                    <button className='multi-btn'>
                        <i class="fa fa-plus" onClick={() => addFilter()} style={{ color: "white", cursor: "pointer" }} aria-hidden="true"></i>
                    </button>

                </div>
            </div>
            {
                filters.map((f, i) => (
                    <div className='row' key={i}>
                        <div className='col-md-3'>
                            <select
                                className={"form-control"}
                                title={""}
                                value={f[0]}
                                disabled
                            >
                                <option>Select</option>
                                {
                                    props.columns?.map((p, i) => (
                                        p.isVisible && <option key={i} value={p.key}>{p.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select
                                className={"form-control"}
                                title={""}
                                value={f[1]}
                                disabled
                            >
                                <option>Select</option>
                                {
                                    conditionalOperator?.map((p, i) => (
                                        <option key={i} value={p.id}>{p.value}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <input
                                className={"form-control"}
                                title={""}
                                placeholder='Value'
                                value={f[1] === "like" ? helper.removeFirstLastChar(f[2]) : f[2]}
                                disabled
                            />

                        </div>
                        <div className='col-md-2'>
                            <select
                                className={"form-control"}
                                title={""}
                                value={f[3]}
                                disabled
                            >
                                <option>Logical</option>
                                {
                                    logicalOperator?.map((p, i) => (
                                        <option key={i} value={p.id}>{p.value}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-md-1 d-flex align-items-center justify-content-center'>
                            <button className='multi-btn' style={{background:'red'}}>
                                <i className="fa fa-times cursor-pointer" onClick={() => removeFilter(i)} aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                ))
            }
            <div className='row'>
                <div className='col'>
                    <button className='btn btn-primary' disabled={!filters.length} onClick={() => updateColumns()}>Apply</button>
                </div>
            </div>
        </div>
    )
}

export default Table;