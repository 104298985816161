import React from 'react';
import { generateNotificationString, generateNotificationStringWithName, generateBulkOrderDetails } from "../../../Utils/notifications";
import { useHistory } from 'react-router-dom';

const NotificationImportant = (props) => {
    // console.log(props.allData.open)

    const history = useHistory();

    const updateRoute = (date) => {
        history.push(`/staffing_sheet?start=${date}`)
    }

    return (
        <div className="">
            <table className="table table-bordered" >
                <thead>
                    <tr>
                        <th className="important-width">Bonus Orders</th>
                        <th className="important-width">New Orders</th>
                        <th className="important-width">Applied(Cont.)</th>
                        <th className="important-width">Confirmation(Client)</th>
                        <th className="important-width">Acknowledgement(Cont.)</th>
                        <th className="important-width">CAN</th>
                        <th className="important-width">CO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="notification-row">
                        <td className=" important-width">
                            <div className="notification-scrolling">
                                <div
                                    
                                >
                                    {generateBulkOrderDetails(props.allData.bulkOrders, props.handleUpdateBonus)}
                                </div>
                            </div>
                        </td>
                        <td className=" important-width">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.open ? generateNotificationString(props.allData.open, updateRoute) : generateNotificationString({})}
                            </div>
                        </td>

                        <td className="important-width">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.assigned_by_admin
                                    ?
                                    generateNotificationStringWithName(props.allData.assigned_by_admin, [...props.type], props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['acceptance'], "acceptance", props.handleSingleDate)
                                    : null}
                            </div>

                        </td>

                        <td className="important-width">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.approved_by_client ? generateNotificationStringWithName(props.allData.approved_by_client, [...props.type], props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['confirmation'], "confirmation", props.handleSingleDate) : null}
                            </div>

                        </td>

                        <td className="important-width">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.acknowledged_by_contractor ? generateNotificationStringWithName(props.allData.acknowledged_by_contractor, [...props.type], props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['acknowledge'], "acknowledge", props.handleSingleDate) : null}
                            </div>

                        </td>

                        <td className="important-width color-red">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.client_cancelled ? generateNotificationStringWithName(props.allData.client_cancelled, [...props.type], props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['can'], "can", props.handleSingleDate) : null}
                            </div>

                        </td>

                        <td className="important-width color-red">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.contractor_cancelled ? generateNotificationStringWithName(props.allData.contractor_cancelled, [...props.type], props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['co'], "co", props.handleSingleDate) : null}
                            </div>

                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
    )
}

export default NotificationImportant;