import React, { Component, useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardBody,
} from "reactstrap";
import Table from '../../../../../component/DynamicTable';
import Search from '../../filter/shiftStatus';
import EmptyComponent from '../../../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../../../store/actions";
import { getWorkSummarReport, saveFilter, getColumns } from "../api";
import * as helper from "../helper";
import SaveFilter from "../../filter/saveFilter";
import GetFilter from "../../filter/getFilter";
import { notiflixLoaderStart, notiflixLoaderEnd, notificationStatus } from '../../../../../component/Notiflix/notiflix';
import ModalLayout from "../../../../../component/ModalLayout";
import { generateFilter } from '../../filter/shiftStatus'

const defaultColumn = ["Client_Name",  "Shift_Name", "Contractor_First_Name", "Contractor_Last_Name", "Pay_Start_Date", "Pay_End_Date", "Order_Client_Confirm", "Order_Contractor_Acknowledge", "Shift_Name", "timesheet_verified", "Contractor_Type", "Cheque_Number(#)", "Cheque_Date", "Cheque_Amount", 'Invoice_Number(#)', "Invoice_Date", "Invoice_Amount", "hours_worked"]


const OrderReport = (props) => {

    const [allData, setAllData] = useState(null)
    const [breadcrumbItems, setBreadcrumbItems] = useState([])
    const [changeColumn, setChangeColumn] = useState(false)
    const [columns, setColumns] = useState([])
    const [modalFilter, setModalFilter] = useState(false)
    const [filters, setFilters] = useState([])
    const [filterFromApply, setFilterFromApply] = useState(null)
    const [extraFilter, setExtraFilter] = useState([])
    const [sort, setSort] = useState(false)
    const [modalFilterSave, setModalFilterSave] = useState(false)
    const [modalFilterGet, setModalFilterGet] = useState(false)

    useEffect(() => {
        props.setBreadcrumbItems("Reports", breadcrumbItems);
    }, [])

    useEffect(() => {
        // getData(filters, columns)
    }, [])

    useEffect(() => {
        getAllColumns()
    }, [])

    const getAllColumns = async() =>{
        let res = await getColumns({reportName:"shift_summary"})
        setColumns((helper.cretateColumn(res.generatedColumns, defaultColumn, res.dbColumns)))
    }

    const getData = async (filter, sort) => {
        console.log(filter, "dfdsf");
        let _filter = filter ? filter : [...filters, ...extraFilter]
        let request = {
            "report_name": "shift_summary",
            "filters": [
                ..._filter
            ],
            "sort": helper.convertColumnForSort(sort ? sort : columns)
        }
        let data = await getWorkSummarReport(request)
        console.log(sort.length);
        
        if (data) {
            updateData(data.data)
        }
    }
    const updateData = (data) => {
        setAllData(data.map((d) => {
            return { ...d, timesheet_verified: d.Order_State === 4 ? "YES" : "NO" }
        }))
    }

    //From top filtres
    const handleFilter = async (filterData) => {
        let _allFilter = []
        console.log(filterData);
        setFilters(filterData)

        if (filterData.length)
            _allFilter = [
                ..._allFilter,
                ...filterData
            ]
        if (extraFilter.length)
            _allFilter = [
                ..._allFilter,
                ...extraFilter
            ]
        getData(_allFilter, columns)
    }

    //From colum and sort
    const updateColumns = (columns, isCallAPI) => {
        console.log(columns);
        setColumns(columns)
        if (isCallAPI)
            getData(null, columns)
    }
    //From colum and sort

    //From Popup filter
    const updateExtraFilter = (extra) => {
        setExtraFilter(extra)


        let _allFilter = []
        if (filters.length)
            _allFilter = [
                ..._allFilter,
                ...filters
            ]
        if (extra.length)
            _allFilter = [
                ..._allFilter,
                ...extra
            ]

        getData(_allFilter, columns)
    }

    const saveFilterData = async (name) => {
        notiflixLoaderStart()
        let data = {
            "reportName": name.name,
            "reportType": "shift_summary",
            "savedReportData": JSON.stringify({ filters, extraFilter, columns })
        }
        let res = await saveFilter(data)
        if (res) {
            setModalFilterSave(false)
            notificationStatus({ status: true, message: "Succcessfully saved" })
        }
        else {
            notificationStatus({ status: false, message: "Error in saving filter" })
        }
        notiflixLoaderEnd()
    }

    const applyFilter = async (data) => {
        let _data = JSON.parse(data)
        console.log(_data);
        setFilters(generateFilter(_data.filters))
        setExtraFilter(_data.extraFilter)
        setColumns(_data.columns)
        setModalFilterGet(false)
        setFilterFromApply(_data.filters)
        getData([...generateFilter(_data.filters), ..._data.extraFilter], _data.columns)
    }

    return (
        <>
            <Search
                handleFilter={handleFilter}
                title="Shift Status Report"
                setModalFilter={setModalFilterSave}
                getModalFilterGet={setModalFilterGet}
                filterFromApply={filterFromApply}
            />
            {
                allData?.length > 0 && <Card>
                    <CardBody style={{ overflowX: 'scroll' }}>
                        <Table
                            rows={allData}
                            columns={columns}
                            onSearch={false}
                            fetchSelectedPage={null}
                            changeColumn={changeColumn}
                            handleChangeColumn={setChangeColumn}
                            updateColumns={updateColumns}
                            setSort={setSort}
                            sort={sort}
                            exportExcel={() => { }}
                            modalFilter={modalFilter}
                            setModalFilter={setModalFilter}
                            extraFilter={extraFilter}
                            updateExtraFilter={updateExtraFilter}
                            reportName="Shift-Status-Report"
                        />
                    </CardBody>
                </Card>
            }
            {
                allData?.length === 0 && <EmptyComponent />
            }
             {
                modalFilterSave
                    ?
                    <ModalLayout
                        modal={true}
                        toggle={() => setModalFilterSave(null)}
                        title={'Save Filter'}
                        size="md"
                    >
                        <SaveFilter
                            saveFilter={saveFilterData}
                        />
                    </ModalLayout>
                    : null
            }
            {
                modalFilterGet
                    ?
                    <ModalLayout
                        modal={true}
                        toggle={() => setModalFilterGet(null)}
                        title={'View Filter'}
                        size="lg"
                    >
                        <GetFilter
                            applyFilter={applyFilter}
                            reportType={"shift_summary"}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    );

}

export default connect(null, { setBreadcrumbItems })(OrderReport);;