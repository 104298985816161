export const ORDER_REPORT = "/reports/order-report";
export const PAYRATE_REPORT = "/reports/payrate-report";
export const BILLRATE_REPORT = "/reports/billrate-report";
export const PAYROLL_REPORT = "/reports/payroll-report";
export const INVOICE_REPORT = "reports/invoice-report";

export const REPORT_SUMMARY = "/reports/summary-report";

export const WORK_SUMMARY = "/reports/reports-summary";
export const REPORT_COLUMN = "/reports/column";
export const GET_REPORT = "/reports/custom-report";
export const SAVE_REPORT = "/reports/custom-report";
