import React, { Component }  from 'react';
import { TimeDiff, changeTime, dateTimeFormatFull, compareTwoTime, dateArithmetic, dateUSStandard, dateTimeBasicFormantSplit } from './dateFunctions';
import { dateTimeBasicFormant, updateShiftHours, currentDate, updateDateIfNightShift } from "./dateFunctions"

export const getBulkDetails = (bulkOrders) => {
    let components = {};
    if (bulkOrders) {
        let _dates = Object.keys(bulkOrders);
        _dates.map((b, i) => {
            let _date = bulkOrders[b];
            if (!components[b])
                components[b] = [];
            let _shifts = Object.keys(_date);
            _shifts.map((s, j) => {
                let _shift = _date[s];

                if (!components[b][s])
                    components[b][s] = [];

                _shift.map((o, k) => {
                    components[b][s].push({
                        ...o,
                        selectedSingle: 0,
                        selectedResponse: o.responses
                    })
                })
            })
        })
    }

    return components;
}

export const updateBulkDetails = (bulkOrders) => {
    // console.log("asdsad")
    let components = [];
    let _dates = Object.keys(bulkOrders);
    _dates.map((b, i) => {
        let _date = bulkOrders[b];
        let _shifts = Object.keys(_date);
        _shifts.map((s, j) => {
            let _shift = _date[s];
            _shift.map((o, k) => {
                if (parseInt(o.selectedSingle) > 0) {
                    // console.log("asdsasdsaad")
                    components.push({
                        "order": o.id,
                        "ctr": parseInt(o.selectedSingle)
                    })
                }

            })
        })
    })
    // console.log(components)

    return components;
}


export const generateNotificationStringWithName = (pastOrders, futureOrders, extra, message) => {
    let schText = "scheduled";
    let str = [];
    if (pastOrders?.length) {
        let date = dateTimeBasicFormantSplit(pastOrders[0].start_date)
        let dateE = dateTimeBasicFormantSplit(pastOrders[0].end_date)
        schText = (pastOrders[0].state <= 2) ? "applied" : "scheduled"
        str.push(<>
            Contractor already {schText}  at   <strong>{pastOrders[0].name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
            <br />
            Shift Created at: {dateTimeBasicFormant(pastOrders[0].created_at)}
        </>)
    }
    else if (futureOrders?.length) {
        let date = dateTimeBasicFormantSplit(futureOrders[0].start_date)
        let dateE = dateTimeBasicFormantSplit(futureOrders[0].end_date)
        schText = futureOrders[0].state <= 2 ? "applied" : "scheduled"
        str.push(<>
            Contractor already {schText} at   <strong>{futureOrders[0].name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
            <br />
            Shift Created at: {dateTimeBasicFormant(futureOrders[0].created_at)}
        </>)
    }
    else if (extra?.id) {
        let date = dateTimeBasicFormantSplit(extra.start_date)
        let dateE = dateTimeBasicFormantSplit(extra.end_date)
        schText = extra.state <= 2 ? "applied" : "scheduled"
        str.push(<>
            Contractor already {schText} at   <strong>{extra.name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
            <br />
            Shift Created at: {dateTimeBasicFormant(extra.created_at)}
        </>)
    }
    else if (message) {
        str.push(message)
    }
    return str
}